import React, { useState, useEffect, useId } from "react";
import axios from "axios";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import "./AccountSettings.css"; // Import your CSS file
import { fetchUserData } from "../MyPlan/hooks/Data";
import PaymentUpdate from "../Organizations/PaymentUpdate";

const AccountSettings = () => {
  const { user, dispatch } = useAuthContext();
  const [preferredUnitSystem, setPreferredUnitSystem] = useState("imperial");
  // const [preferredUnitSystem, setPreferredUnitSystem] = useState("imperial");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); //navigate to organizations manager

  const baseURLAWS = process.env.REACT_APP_baseUrl;
  //let baseURLAWS = "https://api.myyouthspan.com";
  //const baseURLAWS = process.env.REACT_APP_localBaseUrlAWS;

  const [orgData, setOrgData] = useState(null);
  const [familyData, setFamilyData] = useState(null);
  const [fetchedData, setFetchedData] = useState(false);
  const userId = JSON.parse(localStorage.getItem('mysUser')).userId;

  const getOrgData = (orgtype) => {
    axios
      .get(
        baseURLAWS +
          "api/organizations/orginfo/" +
          user.userId +
          "/" +
          orgtype,
      )
      .then((response) => {
        if (orgtype === "F") {
          setFamilyData(response.data);
        } else if (orgtype === "C") {
          setOrgData(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching family/organization data: ", error);
      })
      .finally(() => {
        setFetchedData(true);
      });
  };

  useEffect(() => {
    if (user) {
      getOrgData("F");
      getOrgData("C");
    }
  }, [user]);

  useEffect(() => {
    const fetchUData = async (userId) => {
      console.log('fetchUData')
      try {
        const data = await fetchUserData(userId);
        console.log(userId);
        console.log(data.preferredUnitSystem);
        if (data.preferred_unit_system === 'imperial') {
          setPreferredUnitSystem('imperial')
        } else {
          setPreferredUnitSystem('metric')
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUData(userId);
  }, []);

  const handleUnitSystemChange = (event) => {
    setPreferredUnitSystem(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // const csrfToken = getCookie("csrftoken")
    // const jwtToken = localStorage.getItem("jwt").replace(/^"(.*)"$/, "$1");
    // const jwtToken = localStorage.getItem("jwt").replace(/^"(.*)"$/, "$1");
    setLoading(true);

    // Set up the request endpoint
    const accountSettingsUrl = baseURLAWS + "api/users/account-settings/";
    // const userId = JSON.parse(localStorage.getItem('mysUser')).userId;
    // console.log(jwtToken);
    // console.log(`Bearer ${jwtToken}`);
    // Update the preferred unit system
    axios
      .put(
        accountSettingsUrl,
        { "userId": userId,
          "preferred_unit_system": preferredUnitSystem },
        // {
        //   headers: {
        //     Authorization: `Bearer ` + jwtToken,
        //     // "X-CSRFToken": csrfToken,
        //   },
        // },
        { "userId": userId,
          "preferred_unit_system": preferredUnitSystem },
        // {
        //   headers: {
        //     Authorization: `Bearer ` + jwtToken,
        //     // "X-CSRFToken": csrfToken,
        //   },
        // },
      )
      .then((response) => {
        // Handle success
        setLoading(false);
        console.log(response.data);
        dispatch({ type: "UPDATE_PREFERRED_UNIT_SYSTEM", preferredUnitSystem });
      })
      .catch((error) => {
        // Handle error
        setLoading(false);
      });
  };

  //navigate buttons
  const handleFamilyPlan = () => {
    navigate("/familyplan");
  };
  const handleOrganizationPlan = () => {
    navigate("/organizationplan");
  };

  const handleLeave = (userid, orgtype) => {
    axios
      .post(baseURLAWS + "api/organizations/leave", {
        user_id: userid,
        orgtype: orgtype,
      })
      .then(() => {
        if (orgtype === "F") {
          setFamilyData(null);
        } else if (orgtype === "C") {
          setOrgData(null);
        }
      })
      .catch((error) => {
        console.error("Error leaving org/family plan: ", error);
      });
  };

  const handleCreateFamily = () => {
    axios
      .post(baseURLAWS + "api/organizations/createfamily", {
        user_id: user.userId,
      })
      .then((response) => {
        console.log("Response: ", response.data);
        navigate("/familyplan");
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  const handlePaymentUpdateClick = () => {
    navigate('/payment_update'); // Navigate to the payment update screen
  };

  if (loading || !user) {
    return <p>Loading...</p>;
  }

  return (
    <div className="account-settings-container">
      <br />
      <br />
      <h2 className="account-settings-header">Account Settings</h2>
      <p>Email: {user.email}</p>
      <p>Username: {user.firstName}</p>

      {user.isPremium === true && orgData ? (
        orgData.is_manager ? (
          <button
            className="account-settings-button"
            onClick={handleOrganizationPlan}
          >
            View Organization
          </button>
        ) : (
          <button
            className="account-settings-button"
            onClick={() => handleLeave(user.userId, "C")}
          >
            Leave Organization
          </button>
        )
      ) : null}

      {user.isPremium === true && fetchedData ? (
        familyData ? (
          familyData.is_manager ? (
            <button
              className="account-settings-button"
              onClick={handleFamilyPlan}
            >
              View Family
            </button>
          ) : (
            <button
              className="account-settings-button"
              onClick={() => handleLeave(user.userId, "F")}
            >
              Unsubscribe Family
            </button>
          )
        ) : (
          <button
            className="account-settings-button"
            onClick={handleCreateFamily}
          >
            Create Family
          </button>
        )
      ) : null}

      <form onSubmit={handleSubmit}>
        <div className="account-settings-field">
          <label className="account-settings-label">
            Preferred Unit System:
          </label>
          <select
            className="account-settings-select"
            value={preferredUnitSystem}
            onChange={handleUnitSystemChange}
          >
            <option value="imperial">Imperial</option>
            <option value="metric">Metric</option>
            {/* <option value="metric">Metric</option> */}
          </select>
        </div>
        <button className="account-settings-button" type="submit">
          Update Settings
        </button>
      </form> 
      <button className="update-payment-button" type="submit" onClick={handlePaymentUpdateClick}>
        Update Payment Method
      </button>
    </div>
  );
};

export default AccountSettings;
