import React from 'react'
import InputMulti from '../../../components/questionnaireElement/InputMulti'
import { useEffect, useState } from 'react'
import { durationOptions, servingsPerDay } from '../Sections/OptionsTable/MyDietOptions'
import {
    processedMeatLabel, poultryLabel, redMeatLabel, fishLabel, refinedGrainLabel, wholeGrainLabel,
    vegetablesLabel, fruitLabel, legumesLabel, milkLabel, cheeseLabel, butterLabel, dairyOtherLabel,
} from '../Sections/Lables/MyDiteLabels'
import {
    processedMeatName, poultryName, redMeatName, fishName, refinedGrainName, wholeGrainName,
    vegetablesName, fruitName, legumesName, milkName, cheeseName, butterName, dairyOtherName,
} from '../Sections/NameTable/MyDietName'
import { getValueByLabel, getLabelByValue } from '../actions'
import { durationOptionsMap } from './LabelValueMap'
const formDataKey = 'IntakeForm'; 
const meatServing = '1 Serving ~ 3oz or 85g'
const fruitsVeggiesServing = '1 Serving ~ 1 cup or 100g'
const grainServing = '1 Serving ~ 1 slice bread or 1oz or 28g'
const legumesServing = '1 Serving ~ 1/2 cup or 75g'
const diaryServing = '1 Serving ~ 1 cup or 200g'
export default function MyDiet({dataState, setDataState, errors, limit, setLimit}) {
  const [cigaretteStatus, setCigaretteStatus] = useState('');
  const [formData, setformData] = useState(sessionStorage.getItem(formDataKey) || {});
  const DietNameList = [
    processedMeatName, poultryName, redMeatName, fishName, refinedGrainName, wholeGrainName,
    vegetablesName, fruitName, legumesName, milkName, cheeseName, butterName, dairyOtherName,
  ]
  useEffect(() => {
    const savedData = sessionStorage.getItem(formDataKey);
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      DietNameList.forEach((dietName, id) => {
        parsedData[dietName] = getLabelByValue(durationOptionsMap, parsedData[dietName])
      })
      setformData(parsedData);
    }
  }, []);
  const handleInputChange = (name, value) => {
    const updatedformData = {
      ...formData,
      [name]: typeof value === 'boolean' || ['dob', 'toBedTime', 'getUpTime'].includes(name) ?
        value : isNaN(parseFloat(value)) ? value : parseFloat(value)
    };
    setformData(updatedformData);
    sessionStorage.setItem(formDataKey, JSON.stringify(updatedformData));
    if (name === 'cigarettesUse') {
      setCigaretteStatus(value);
    }
    let newValue = {...dataState, [name]:['dob', 'toBedTime', 'getUpTime'].includes(name) ?
        value : parseFloat(value)}
    setDataState(newValue)
  };
  return (
          <form>
            <div class='inlineContainer'>
              <small class="form-text">My Diet</small>
            </div>
            <div class='inlineContainer'>
              <div>For each meat/fish listed, select the option indicating how many servings on average you have consumed during the past year</div>
            </div>
            <InputMulti inputs={[
              {type: 'InputSingle', label: processedMeatLabel, options: servingsPerDay, name: processedMeatName, instruction: meatServing},
              {type: 'InputSingle', label: poultryLabel, options: servingsPerDay, name: poultryName, instruction: meatServing},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors} limit={limit} setLimit={setLimit}
            />
            <InputMulti inputs={[
              {type: 'InputSingle', label: redMeatLabel, options: servingsPerDay, name: redMeatName, instruction: meatServing},
              {type: 'InputSingle', label: fishLabel, options: servingsPerDay, name: fishName, instruction: meatServing},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors} limit={limit} setLimit={setLimit}
            />
            <div class='inlineContainer'>
              <div>For each of the grain options listed, select the option indicating how many servings on average you have consumed during the past year</div>
            </div>
            <InputMulti inputs={[
              {type: 'InputSingle', label: refinedGrainLabel, options: servingsPerDay, name: refinedGrainName, instruction: grainServing},
              {type: 'InputSingle', label: wholeGrainLabel, options: servingsPerDay, name: wholeGrainName, instruction: grainServing},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors} limit={limit} setLimit={setLimit}
            />
            <div class='inlineContainer'>
              <div>For each food listed, select the option indicating how many servings on average you have consumed during the past year</div>
            </div>
            <InputMulti inputs={[
              {type: 'InputSingle', label: vegetablesLabel, options: servingsPerDay, name: vegetablesName, instruction: fruitsVeggiesServing},
              {type: 'InputSingle', label: fruitLabel, options: servingsPerDay, name: fruitName, instruction: fruitsVeggiesServing},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors} limit={limit} setLimit={setLimit}
            />
            <InputMulti inputs={[
              {type: 'InputSingle', label: legumesLabel, options: servingsPerDay, name: legumesName, instruction: legumesServing},
              {type: 'null'},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors} limit={limit} setLimit={setLimit}
            />
            <div class='inlineContainer'>
              <div>For each dairy listed, select the option indicating how many servings on average you have consumed during the past year</div>
            </div>
            <InputMulti inputs={[
              {type: 'InputSingle', label: milkLabel, options: servingsPerDay, name: milkName, instruction: diaryServing},
              {type: 'InputSingle', label: cheeseLabel, options: servingsPerDay, name: cheeseName, instruction: diaryServing},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors} limit={limit} setLimit={setLimit}
            />
            <InputMulti inputs={[
              {type: 'InputSingle', label: butterLabel, options: servingsPerDay, name: butterName, instruction: diaryServing},
              {type: 'InputSingle', label: dairyOtherLabel, options: servingsPerDay, name: dairyOtherName, instruction: diaryServing},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors} limit={limit} setLimit={setLimit}
            />
          </form>
  )
}
