
const genderLabel = "What is your gender?";
const dateOfBirthLabel = "What is your date of birth?";
const cigarettesUseLabel = "Have you ever used cigarettes?";
const formerCigarettesYearsLabel = "How many years did you use cigarettes?";
const currentCigarettesYearsLabel = "How many years have you used cigarettes?";
const smoking_frequency = "How many cigarettes do you smoke per day?";
const alcoholDrinksPerWeekLabel = "Please input the average number of alcoholic drinks you consume per week.";
const calorieRestrictionLabel = "Do you restrict your calorie intake?";
const transFatIntakeLabel = "Trans fat intake";
const refinedGrainLabel = "Refined grains intake";
const wholeGrainLabel = "Whole grains intake";
const legumesLabel = "Legumes intake";
const processedMeatLabel = "Processed meat intake";
const poultryLabel = "Poultry intake";
const redMeatLabel = "Red meat intake";
const vegetablesLabel = "Vegetables intake";
const fruitLabel = "Fruit intake";
const waterIntakeLabel = "Water intake";
const sugarySugarLabel = "Refined sugar intake";
const artificiallySugarLabel = "Artificial sugar intake";
const cardioLabel = "How many hours of cardio exercise do you do per week?";
const strengthTrainingLabel = "How many minutes do you spend on strength training per week?";
const actualSleepHoursLabel = "During the past month, how many hours of actual sleep did you get at night? (This may be different from the number of hours you spend in bed.)";
const activitiesSaunaBathingLabel = "How many times per week do you take sauna baths?";
const stressQualityLabel = "How would you rate your stress quality?";
const sleepQualityLabel = "How would you rate your sleep quality?";
const calciumLabel = "Calcium intake";
const fishOilOmega3Label = "Fish oil/Omega-3 intake";
const matchaGreenTeaLabel = "Matcha/Green tea intake";

export {
    genderLabel, dateOfBirthLabel, cigarettesUseLabel, formerCigarettesYearsLabel, currentCigarettesYearsLabel, 
    alcoholDrinksPerWeekLabel, calorieRestrictionLabel, transFatIntakeLabel, refinedGrainLabel, wholeGrainLabel, 
    legumesLabel, processedMeatLabel, poultryLabel, redMeatLabel, vegetablesLabel, fruitLabel, waterIntakeLabel, 
    sugarySugarLabel, artificiallySugarLabel, cardioLabel, strengthTrainingLabel, actualSleepHoursLabel, 
    activitiesSaunaBathingLabel, stressQualityLabel, calciumLabel, fishOilOmega3Label, matchaGreenTeaLabel, sleepQualityLabel, smoking_frequency
};
