import convert from 'convert-units'
import axios from 'axios';
import { newExerciseOptions, newTimeOptions } from './Sections/LabelValueMap';

//const baseUrl = process.env.REACT_APP_localBaseUrlAWS;
const baseUrl = process.env.REACT_APP_baseUrl;

const baseUrlLifestyle = baseUrl + "api/lifestyle";
const baseUrlUser = baseUrl + "api/users";
const mysUser = 'mysUser'

export const submitDataToModel = () => {
}

export const fetchUserData = async (userId) => {
    return await axios.get(
        `${baseUrlUser}/get-user-data/${userId}`,
        {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(
            response => response.data
        )
        .catch(error => {
            if (error.response && error.response.status === 500) {
                return null
            } else {
                console.error('Error fetching form data:', error)
                throw error
            }
        })
}

export const addUserInfoIntoFormData = (formData, formDataUnit, formDataUnitDefault) => {
    const userInfo = JSON.parse(localStorage.getItem('mysUser'));
    formData = { ...formData, ...userInfo };
    const dob = new Date(formData['dob']);
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    formData['age'] = age;
    Object.keys(formDataUnit).forEach(key => {
        if (formData[key] !== undefined && formDataUnit[key] && formDataUnitDefault[key]) {
            formData[key] = UnitConvert(formData[key], formDataUnit[key], formDataUnitDefault[key], key);
        }
    });

    return formData;
}

export const submitInitialData = async (formData) => {
    return axios.post(
        `${baseUrlLifestyle}/submit-initial-plan/`,
        formData,
    )
        .then(response => response.data)
        .catch(error => {
            if (error.response && error.response.status === 500) {
                return null;
            } else {
                console.error('Error submitting form data:', error);
                throw error;
            }
        });
};

export function getValueByLabel(options, label) {
    const option = options.find(option => option.label === label);
    return option ? option.value : 0; // Returns null if no match is found
}

export function getLabelByValue(options, value) {
    const option = options.find(option => option.value === value);
    return option ? option.label : 0; // Returns null if no match is found
}

export const getLifestyleResponseData = async (userId) => {
    return axios.get(
        `${baseUrlLifestyle}/read-one-lifestyle-response/${userId}`,
        {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(response => response)
        .catch(error => {
            if (error.response && error.response.status === 500) {
                return null;
            } else {
                console.error('Error fetching form data:', error);
                throw error;
            }
        });
}

export const extractUnit = (unit) => {
    const res = unit.includes('/') ? unit.split('/')[0] : unit;
    return res === 'tbsp' ? 'Tbs' : res;
};

const isDate = (unit) => {
    const validUnits = ["day", "month", "week"];
    return validUnits.includes(unit.toLowerCase());
}

const dateConvert = (val, prevUnit, curUnit) => {
    const unitToDays = {
        Day: 1,
        Week: 7,
        Month: 30
    };

    const days = val * unitToDays[prevUnit] / unitToDays[curUnit];
    return days;
}

export const vitaminAConvert = (val, prevUnit, curUnit) => {
    const unit = {
        ug: 1,
        IU: 3
    };

    const ans = val * unit[prevUnit] / unit[curUnit];
    return ans;
}
export const vitaminDConvert = (val, prevUnit, curUnit) => {
    const unit = {
        ug: 1,
        IU: 40
    };

    const ans = val * unit[prevUnit] / unit[curUnit];
    return ans;
}

export const vitaminEConvert = (val, prevUnit, curUnit) => {
    const unit = {
        mg: 1,
        IU: 0.67
    };

    const ans = val * unit[prevUnit] / unit[curUnit];
    return ans;
}

const oliveOilConvert = (val, prevUnit, curUnit) => {
    const density = 0.918; // g/mL
    const unitToVolume = {
        "g": 1 / density,
        "Tbs": 14.7868,
        "tsp": 4.92892
    };

    let volumeInMl;
    if (prevUnit === "g") {
        volumeInMl = val * unitToVolume[prevUnit];
    } else {
        volumeInMl = val * unitToVolume[prevUnit];
    }

    if (curUnit === "g") {
        return volumeInMl * density;
    } else {
        return volumeInMl / unitToVolume[curUnit];
    }
};

export const UnitConvert = (val, prevUnit, curUnit, name) => {
    const cleanPrevUnit = extractUnit(prevUnit);
    const cleanCurUnit = extractUnit(curUnit);
    if (isDate(cleanPrevUnit)) {
        return dateConvert(val, cleanPrevUnit, cleanCurUnit);
    }
    if ((cleanPrevUnit === "g" || cleanPrevUnit === "Tbs" || cleanPrevUnit === "tsp") &&
        (cleanCurUnit === "g" || cleanCurUnit === "Tbs" || cleanCurUnit === "tsp")) {
        return oliveOilConvert(val, cleanPrevUnit, cleanCurUnit);
    }
    if (name === 'vitaminE') {
        return Math.round(vitaminEConvert(val, cleanPrevUnit, cleanCurUnit));
    }
    return convert(val).from(cleanPrevUnit).to(cleanCurUnit);
}
