import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthContext } from "../hooks/useAuthContext" 

function GuestRoute({ children}) {

    const { user } = useAuthContext();
    
    if (!user){
       return <Navigate to="/" />;
    }

  return (
    children
  )
}

export default GuestRoute