const DATE_OF_BIRTH = "dob";      
const AGE = 'age';                          
const GENDER = "gender";                                    
const CIGARETTES_USE = "usedCigarettes";                    
const FORMER_CIGARETTES_YEARS = "smoking_duration";          
const CURRENT_CIGARETTES_YEARS = "smoking_duration";         
const ALCOHOL_DRINKS_PER_WEEK = "numberOfAlcoholicDrink";   
const calorieRestrictionName = "calorieRestriction";        
const transFatIntakeName = "fat_trans";                     
const refinedGrainName = "refinedGrain";                    
const wholeGrainName = "wholeGrain";                        
const legumesName = "legumes";                              
const processedMeatName = "processedMeat";                  
const poultryName = "poultry";                              
const redMeatName = "redMeat";                              
const vegetablesName = "vegetables";                        
const fruitName = "fruit";                                  
const waterIntakeName = "water";                            
const sugarySugar = "refined_sugar";                        
const artificiallySugar = "artificial_sweetener";           
const cardioName = 'cardio';                                
const strengthTrainingName = "muscleStrength";              
const actualSleepHoursName = "timeActualSleep";              
const sleepQualityName = "sleepQuality";                    
const activitiesSaunaBathingName = "saunaBathing";           
const stressQualityName = "stress_quality";                  
const calciumName = "calcium";                               
const fishOilOmega3Name = "fishOilOrOmega3";                 
const matchaGreenTeaName = "matchaOrGreenTea";
const smoking_frequencyName = 'smoking_frequency';              
export {
    DATE_OF_BIRTH, GENDER, CIGARETTES_USE, AGE,
    FORMER_CIGARETTES_YEARS, CURRENT_CIGARETTES_YEARS, ALCOHOL_DRINKS_PER_WEEK,
    calorieRestrictionName, transFatIntakeName, refinedGrainName, wholeGrainName,
    legumesName, processedMeatName, poultryName, redMeatName, vegetablesName,
    fruitName, waterIntakeName, sugarySugar, artificiallySugar,
    cardioName, strengthTrainingName, actualSleepHoursName, sleepQualityName,
    activitiesSaunaBathingName, stressQualityName, calciumName, fishOilOmega3Name,
    matchaGreenTeaName, smoking_frequencyName
};
