import React, { useEffect, useState } from "react";
import axios from "axios";
import Popup from "./Popup";
import InviteForm from "./InviteForm";
import MemberTable from "./MemberTable";
import PendingInviteTable from "./PendingInviteTable";
import ExpiredInviteTable from "./ExpiredInviteTable";

import "./Organizations.css";

const OrganizationContent = ({ orgtype }) => {
  const userid = JSON.parse(localStorage.getItem("mysUser")).userId;

  const [members, setMembers] = useState([]);
  const [memberCt, setMemberCt] = useState(0);
  const [orgData, setOrgData] = useState(null);
  const [pendingInvites, setPendingInvites] = useState([]);
  const [expiredInvites, setExpiredInvites] = useState([]);

  // customized popup window
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [popupConfirmFunction, setPopupConfirmFunction] = useState(null);

  const handlePopupCancel = () => {
    setShowPopup(false);
  }

  const handlePopupConfirm = () => {
    setShowPopup(false);
    popupConfirmFunction();
  }

  const baseURL = process.env.REACT_APP_baseUrl;
  const infoURL = "api/organizations/orginfo/";

  const fetchOrganizationData = () => {
    axios
      .get(baseURL + infoURL + userid + "/" + orgtype)
      .then((response) => {
        setOrgData(response.data);
        setMemberCt(response.data.content.active_members);

        // seperate pending and expired invites
        setPendingInvites(
          response.data.content.invitations.filter(
            (invite) => new Date().getTime() <= new Date(invite.invite_expiration_date).getTime(),
          ),
        );
        setExpiredInvites(
          response.data.content.invitations.filter(
            (invite) => new Date().getTime() > new Date(invite.invite_expiration_date).getTime(),
          ),
        );

        // make sure manager is first in list
        const managerArray = response.data.content.memberships.filter(
          (member) => member.email === response.data.content.manager_email,
        );
        const otherMembersArray = response.data.content.memberships.filter(
          (member) => member.email !== response.data.content.manager_email,
        );
        setMembers([...managerArray, ...otherMembersArray]);
      })
      .catch((error) => {
        console.error("Error fetching organization data: ", error);
      });
  };

  useEffect(() => {
    fetchOrganizationData();
  }, []);

  return (
    <div>
      <br></br>
      {orgData ? (
        <div>
          <div className="orgtitle">
            {orgData.content.manager_name}'s {orgtype === "F" ? "Family" : "Organization"}
          </div>
          <div className="orgdetails">
            <h4>
              {memberCt} Active Member{memberCt > 1 ? "s" : ""}
            </h4>
            {memberCt >= 1 ? (
            <h4 style={{ color: "green" }}>Plan Active</h4>
          ) : (
            <h4 style={{ color: "orange" }}>Plan Inactive</h4>
          )}

          </div>
          <div className="orgcontainer">
            <div className="org-area left-area">
              <MemberTable
                orgData={orgData}
                orgtype={orgtype}
                members={members}
                fetchOrganizationData={fetchOrganizationData}
                setShowPopup={setShowPopup}
                setPopupContent={setPopupContent}
                setPopupConfirmFunction={setPopupConfirmFunction}
              />
              <PendingInviteTable
                pendingInvites={pendingInvites}
                orgtype={orgtype}
                fetchOrganizationData={fetchOrganizationData}
              />
              <ExpiredInviteTable
                expiredInvites={expiredInvites}
                orgtype={orgtype}
                fetchOrganizationData={fetchOrganizationData}
              />
            </div>
            <div className="org-area right-area">
              <InviteForm
                orgData={orgData}
                orgtype={orgtype}
                fetchOrganizationData={fetchOrganizationData}
                setShowPopup={setShowPopup}
                setPopupContent={setPopupContent}
                setPopupConfirmFunction={setPopupConfirmFunction}
              />
            </div>
          </div>
        </div>
      ) : null}

      {showPopup && (
        <Popup
          message={popupContent}
          onCancel={handlePopupCancel}
          onConfirm={handlePopupConfirm}
        />
      )}
    </div>
  );
};

export default OrganizationContent;
