import React from 'react'
import Menu from '../../../components/questionnaireElement/Menu'
import InputSingle from '../../../components/questionnaireElement/InputSingle'
import InputMulti from '../../../components/questionnaireElement/InputMulti'
import DateInput from '../../../components/questionnaireElement/DateInput'
import {
  DATE_OF_BIRTH, GENDER, CIGARETTES_USE, AGE,
  FORMER_CIGARETTES_YEARS, CURRENT_CIGARETTES_YEARS, ALCOHOL_DRINKS_PER_WEEK,
  calorieRestrictionName, transFatIntakeName, refinedGrainName, wholeGrainName,
  legumesName, processedMeatName, poultryName, redMeatName, vegetablesName,
  fruitName, waterIntakeName, sugarySugar, artificiallySugar,
  cardioName, strengthTrainingName, actualSleepHoursName, sleepQualityName,
  activitiesSaunaBathingName, stressQualityName, calciumName, fishOilOmega3Name,
  matchaGreenTeaName
} from '../archive/CalculatorName'

import {
  aboutMeAlcoholModelName, aboutMeAgeModelName, aboutMeGenderModelName, aboutMeSmokingStatusModelName,
  aboutMeSmokingFrequencyModelName, dietCalorieRestrictionModelName, dietFatTransModelName,
  dietGrainRefinedModelName, dietGrainUnrefinedModelName, dietLegumesModelName, dietMeatProcessedModelName,
  dietMeatUnprocessedModelName, dietMeatPoultryModelName, dietFruitsAndVeggiesModelName, dietWaterModelName,
  dietRefinedSugarModelName, dietArtificialSweetenerModelName, exerciseCardioModelName, exerciseStrengthTrainingModelName,
  mySleepSleepDurationModelName, mySleepSleepQualityModelName, exerciseSaunaFrequencyModelName, nodeStressQualityModelName,
  supplementsCalciumModelName, supplementsFishOilOmega3ModelName, supplementsGreenTeaModelName
} from '../archive/CalculatorModelName'

import {
  genderOptions, cigarettesOptions, calorieIntakeOptions, 
  sweetTimeOptions, sleepQualityOptions, stressQualityOptions, 
  calciumOptions, fishOilOmega3Options,  matchaGreenTeaOptions
} from '../archive/CalculatorOptions'

import {
  genderLabel, dateOfBirthLabel, cigarettesUseLabel, formerCigarettesYearsLabel, currentCigarettesYearsLabel, 
  alcoholDrinksPerWeekLabel, calorieRestrictionLabel, transFatIntakeLabel, refinedGrainLabel, wholeGrainLabel, 
  legumesLabel, processedMeatLabel, poultryLabel, redMeatLabel, vegetablesLabel, fruitLabel, waterIntakeLabel, 
  sugarySugarLabel, artificiallySugarLabel, cardioLabel, strengthTrainingLabel, actualSleepHoursLabel, 
  activitiesSaunaBathingLabel, stressQualityLabel, calciumLabel, fishOilOmega3Label, matchaGreenTeaLabel, sleepQualityLabel
} from '../archive/CalculatorLabel'
import CalculatorInputSingle from '../../../components/questionnaireElement/CalculatorInputSingle'


export default function ExerciseSleepStress({dataState, setDataState, errors, limit}) {
  const handleInputChange = (name, value) => {
    const dbValue = {...dataState, [name]: value}
    setDataState(dbValue)
  }
  const averageMETValue = 4.5;
  return (
    <div>
        <small class="form-text">Your Sleep Cardio and Stress Information</small>
        <InputSingle label={activitiesSaunaBathingLabel} name={exerciseSaunaFrequencyModelName} onChange={handleInputChange} value={dataState[exerciseSaunaFrequencyModelName]} options={['times/week']} error={errors[exerciseSaunaFrequencyModelName]}/>
        <InputSingle label={strengthTrainingLabel} name={strengthTrainingName} onChange={handleInputChange} value={dataState[strengthTrainingName]} options={['mins/week']} error={errors[strengthTrainingName]}/>

        <InputSingle label={actualSleepHoursLabel} name={actualSleepHoursName} onChange={handleInputChange} value={dataState[actualSleepHoursName]} options={['hours']} error={errors[actualSleepHoursName]} limit={limit[actualSleepHoursName]}/>
        <Menu label={sleepQualityLabel} onChange={handleInputChange} options={sleepQualityOptions} value={dataState[sleepQualityName]} name={sleepQualityName} error={errors[sleepQualityName]}/>
        
        <Menu label={stressQualityLabel} options={stressQualityOptions} onChange={handleInputChange} value={dataState[stressQualityName]} name={stressQualityName} error={errors[stressQualityName]}/>
        <CalculatorInputSingle label={cardioLabel} name={cardioName} onChange={handleInputChange} value={dataState[cardioName]} options={['hours/week']} conversionFactor={averageMETValue}  error={errors[cardioName]}/>
    </div>
  )
}
