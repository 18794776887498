import React from 'react'
import { useEffect, useState } from 'react'
import InputCheckBox from '../../../components/questionnaireElement/InputCheckBox'
import { myMedicalHistoryNames } from './NameTable/myMedicalName';
import { myMedicalHistoryOptions } from './OptionsTable/MyMedicalOptions';
import { myMedicalHistoryLabel } from './Lables/myMedicalLabels';
const formDataKey = 'IntakeForm'; 
export default function MyMedical({dataState, setDataState}) {
  const [formData, setformData] = useState(sessionStorage.getItem(formDataKey) || {});
  useEffect(() => {
    const savedData = sessionStorage.getItem(formDataKey);
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setformData(parsedData);
    }
  }, []);
  const handleInputChange = (name, value) => {
    let newValue = {...dataState, [name]:value}
    setDataState(newValue)
  };
  return (
    <form>
        <div class='inlineContainer'>
          <small class="form-text">My Medical History</small>
        </div>
        <InputCheckBox label={myMedicalHistoryLabel} name={myMedicalHistoryNames}
         onChange={handleInputChange} options={myMedicalHistoryOptions} value={dataState}/>
    </form>
  )
}
