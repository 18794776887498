import React from 'react';
import Menu from '../../../components/questionnaireElement/Menu';
import InputSingle from '../../../components/questionnaireElement/InputSingle';
import InputMulti from '../../../components/questionnaireElement/InputMulti';
import DateInput from '../../../components/questionnaireElement/DateInput';
import {
  DATE_OF_BIRTH, GENDER, CIGARETTES_USE, AGE,
  FORMER_CIGARETTES_YEARS, CURRENT_CIGARETTES_YEARS, ALCOHOL_DRINKS_PER_WEEK,
  calorieRestrictionName, transFatIntakeName, refinedGrainName, wholeGrainName,
  legumesName, processedMeatName, poultryName, redMeatName, vegetablesName,
  fruitName, waterIntakeName, sugarySugar, artificiallySugar,
  cardioName, strengthTrainingName, actualSleepHoursName, sleepQualityName,
  activitiesSaunaBathingName, stressQualityName, calciumName, fishOilOmega3Name,
  matchaGreenTeaName
} from '../archive/CalculatorName';

import {
  aboutMeAlcoholModelName, aboutMeAgeModelName, aboutMeGenderModelName, aboutMeSmokingStatusModelName,
  aboutMeSmokingFrequencyModelName, dietCalorieRestrictionModelName, dietFatTransModelName,
  dietGrainRefinedModelName, dietGrainUnrefinedModelName, dietLegumesModelName, dietMeatProcessedModelName,
  dietMeatUnprocessedModelName, dietMeatPoultryModelName, dietFruitsAndVeggiesModelName, dietWaterModelName,
  dietRefinedSugarModelName, dietArtificialSweetenerModelName, exerciseCardioModelName, exerciseStrengthTrainingModelName,
  mySleepSleepDurationModelName, mySleepSleepQualityModelName, exerciseSaunaFrequencyModelName, nodeStressQualityModelName,
  supplementsCalciumModelName, supplementsFishOilOmega3ModelName, supplementsGreenTeaModelName
} from '../archive/CalculatorModelName';

import {
  genderOptions, cigarettesOptions, calorieIntakeOptions, 
  sweetTimeOptions, sleepQualityOptions, stressQualityOptions, 
  calciumOptions, fishOilOmega3Options,  matchaGreenTeaOptions
} from '../archive/CalculatorOptions';

import {
  genderLabel, dateOfBirthLabel, cigarettesUseLabel, formerCigarettesYearsLabel, currentCigarettesYearsLabel, 
  alcoholDrinksPerWeekLabel, calorieRestrictionLabel, transFatIntakeLabel, refinedGrainLabel, wholeGrainLabel, 
  legumesLabel, processedMeatLabel, poultryLabel, redMeatLabel, vegetablesLabel, fruitLabel, waterIntakeLabel, 
  sugarySugarLabel, artificiallySugarLabel, cardioLabel, strengthTrainingLabel, actualSleepHoursLabel, 
  activitiesSaunaBathingLabel, stressQualityLabel, calciumLabel, fishOilOmega3Label, matchaGreenTeaLabel
} from '../archive/CalculatorLabel';
import CalculatorInputSingle from '../../../components/questionnaireElement/CalculatorInputSingle';

export default function Diet({ dataState, setDataState, onChangeUnit, onChangeUnitDefault, waterUnit, errors, limit, setLimit}) {
  const handleInputChange = (name, value) => {
    const dbValue = { ...dataState, [name]: value };
    setDataState(dbValue);
  };

  const meatServing = '1 Serving ~ 3oz or 85g';
  const fruitsVeggiesServing = '1 Serving ~ 1 cup or 100g';
  const grainServing = '1 Serving ~ 1 slice bread or 1oz or 28g';
  const legumesServing = '1 Serving ~ 1/2 cup or 75g';

  return (
    <div>
      <div class='form-text'>Diet</div>
      <p>
        Please input the amout you consume per day for those following
        nutritions
      </p>
      <InputSingle
        label={poultryLabel}
        name={poultryName}
        onChange={handleInputChange}
        value={dataState[poultryName]}
        options={['servings/day']}
        instruction={meatServing}
        error={errors[poultryName]}
        limit={limit[poultryName]}
      />
      <InputSingle
        label={processedMeatLabel}
        name={processedMeatName}
        onChange={handleInputChange}
        value={dataState[processedMeatName]}
        options={['servings/day']}
        instruction={meatServing}
        error={errors[processedMeatName]}
        limit={limit[processedMeatName]}
      />
      <InputSingle
        label={redMeatLabel}
        name={redMeatName}
        onChange={handleInputChange}
        value={dataState[redMeatName]}
        options={['servings/day']}
        instruction={meatServing}
        error={errors[redMeatName]}
        limit={limit[redMeatName]}
      />

      <InputSingle
        label={vegetablesLabel}
        name={vegetablesName}
        onChange={handleInputChange}
        value={dataState[vegetablesName]}
        options={['servings/day']}
        instruction={fruitsVeggiesServing}
        error={errors[vegetablesName]}
        limit={limit[vegetablesName]}
      />
      <InputSingle
        label={fruitLabel}
        name={fruitName}
        onChange={handleInputChange}
        value={dataState[fruitName]}
        options={['servings/day']}
        instruction={fruitsVeggiesServing}
        error={errors[fruitName]}
        limit={limit[fruitName]}
      />
      <InputSingle
        label={legumesLabel}
        name={legumesName}
        onChange={handleInputChange}
        value={dataState[legumesName]}
        options={['servings/day']}
        instruction={legumesServing}
        error={errors[legumesName]}
        limit={limit[legumesName]}
      />

      <InputSingle
        label={refinedGrainLabel}
        name={refinedGrainName}
        onChange={handleInputChange}
        value={dataState[refinedGrainName]}
        options={['servings/day']}
        instruction={grainServing}
        error={errors[refinedGrainName]}
        limit={limit[refinedGrainName]}
      />
      <InputSingle
        label={wholeGrainLabel}
        name={wholeGrainName}
        onChange={handleInputChange}
        value={dataState[wholeGrainName]}
        options={['servings/day']}
        instruction={grainServing}
        error={errors[wholeGrainName]}
        limit={limit[wholeGrainName]}
      />

      <InputSingle
        label={transFatIntakeLabel}
        name={transFatIntakeName}
        onChange={handleInputChange}
        value={dataState[transFatIntakeName]}
        options={['g/day']}
        error={errors[transFatIntakeName]}
        limit={limit[transFatIntakeName]}
      />

      <CalculatorInputSingle
        label={waterIntakeLabel}
        name={waterIntakeName}
        onChange={handleInputChange}
        value={dataState[waterIntakeName]}
        onChangeUnit={onChangeUnit}
        onChangeUnitDefault={onChangeUnitDefault}
        options={['L/day', 'fl-oz/day']}
        currentUnit={waterUnit}
        error={errors[waterIntakeName]}
        limit={limit[waterIntakeName]}
      />
      <InputSingle
        label={sugarySugarLabel}
        name={sugarySugar}
        onChange={handleInputChange}
        value={dataState[sugarySugar]}
        options={['g/day']}
        error={errors[sugarySugar]}
        limit={limit[sugarySugar]}
      />
      <InputSingle
        label={artificiallySugarLabel}
        name={artificiallySugar}
        onChange={handleInputChange}
        value={dataState[artificiallySugar]}
        options={['g/day']}
        error={errors[artificiallySugar]}
        limit={limit[artificiallySugar]}
      />

      <Menu
        label={calorieRestrictionLabel}
        onChange={handleInputChange}
        options={calorieIntakeOptions}
        value={dataState[calorieRestrictionName]}
        name={calorieRestrictionName}
        error={errors[calorieRestrictionName]}
      />
    </div>
  );
}
