import React from 'react'
import Menu from '../../../components/questionnaireElement/Menu'
import { useEffect, useState } from 'react'
import { upsetCountName, controlCountName, stressCountName, confidenceCountName, wayCountName, copeCountName, irritationsCountName, topCountName, angerCountName, difficultiesCountName } from '../Sections/NameTable/MyStressName'
import { upsetCountLabel, controlCountLabel, stressCountLabel, confidenceCountLabel, wayCountLabel, copeCountLabel, irritationsCountLabel, topCountLabel, angerCountLabel, difficultiesCountLabel } from '../Sections/Lables/MyStressLabels'
import { stressOptions } from './OptionsTable/MyStressOptions'
const formDataKey = 'IntakeForm'; 
export default function MyStress({dataState, setDataState, errors}) {
  const [cigaretteStatus, setCigaretteStatus] = useState('');
  const [formData, setformData] = useState(sessionStorage.getItem(formDataKey) || {});
  useEffect(() => {
    const savedData = sessionStorage.getItem(formDataKey);
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setformData(parsedData);
    }
  }, []);
  const handleInputChange = (name, value) => {
    let newValue = {...dataState, [name]:parseFloat(value)}
    setDataState(newValue)
  };
  return (
    <form>
        <div class='inlineContainer'>
            <small class="form-text">My Stress</small>
        </div>
        <Menu label={upsetCountLabel} options={stressOptions} name={upsetCountName} onChange={handleInputChange} value={dataState[upsetCountName]} error={errors[upsetCountName]}/>
        <Menu label={controlCountLabel} options={stressOptions} name={controlCountName} onChange={handleInputChange} value={dataState[controlCountName]} error={errors[controlCountName]}/>
        <Menu label={stressCountLabel} options={stressOptions} name={stressCountName} onChange={handleInputChange} value={dataState[stressCountName]} error={errors[stressCountName]}/>
        <Menu label={confidenceCountLabel} options={stressOptions} name={confidenceCountName} onChange={handleInputChange} value={dataState[confidenceCountName]} error={errors[confidenceCountName]}/>
        <Menu label={wayCountLabel} options={stressOptions} name={wayCountName} onChange={handleInputChange} value={dataState[wayCountName]} error={errors[wayCountName]}/>
        <Menu label={copeCountLabel} options={stressOptions} name={copeCountName} onChange={handleInputChange} value={dataState[copeCountName]} error={errors[copeCountName]}/>
        <Menu label={irritationsCountLabel} options={stressOptions} name={irritationsCountName} onChange={handleInputChange} value={dataState[irritationsCountName]} error={errors[irritationsCountName]}/>
        <Menu label={topCountLabel} options={stressOptions} name={topCountName} onChange={handleInputChange} value={dataState[topCountName]} error={errors[topCountName]}/>
        <Menu label={angerCountLabel} options={stressOptions} name={angerCountName} onChange={handleInputChange} value={dataState[angerCountName]} error={errors[angerCountName]}/>
        <Menu label={difficultiesCountLabel} options={stressOptions} name={difficultiesCountName} onChange={handleInputChange} value={dataState[difficultiesCountName]} error={errors[difficultiesCountName]}/>
    </form>
  )
}
