import React, { useState, useEffect, useMemo, useRef } from 'react';
import './Plan.css';
import './Benefits.css';
import { Form, Button, Collapse, Col, Row, Modal } from 'react-bootstrap';
import axios from 'axios';
import Diet from './Diet';
import Execise from './Exercise';
import Supplements from './Supplements';
import Sleep from './Sleep';
import Benefits from './Benefits';
import LoadingIcon from '../../components/LoadingIcon';
import PlanInstructions from '../../components/PlanInstructions';
import { useAuthContext } from '../../hooks/useAuthContext';
import { fetchUserData } from './hooks/Data';
import { getCookie } from '../../utils/cookie';
import PopUpWarning from '../../components/PopUpWarning';

import { getLifestyleResponseData } from '../FullProfile/actions';
import { sendData } from './hooks/Data';

const initialDiet = {
  meat_processed: null,
  meat_unprocessed: null,
  meat_poultry: null,
  meat_fish: null,
  fruits_and_veggies: null,
  dairy: null,
  grain_whole: null,
  grain_refined: null,
  legumes: null,
  fat_saturated: null,
  fat_trans: null,
  water: null,
  refined_sugar: null,
  artificial_sweetener: null,
  alcohol: null,
  cigarettes: null,
  calorie_restriction: null,
  fasting: null,
};
const initialExercise = {
  cardio_low: null,
  cardio_medium: null,
  cardio_high: null,
  strength_training: null,
  balance: null,
  bone_density: null,
  stretching: null,
  sauna_frequency: null,
  sauna_duration: null,
};
const initialSupplements = {
  multi_vitamins: false,
  vitamin_a: null,
  vitamin_b6: null,
  vitamin_b12: null,
  folic_acid: null,
  vitamin_c: null,
  vitamin_d: null,
  vitamin_e: null,
  vitamin_k: null,
  calcium: null,
  collagen_peptide: false,
  dietary_fiber: null,
  fish_oil_omega_3: null,
  green_tea: null,
  iron: null,
  magnesium: null,
  olive_oil: null,
  probiotics: false,
  protein_supplements: null,
  zinc: null,
};

const Plan = () => {
  const [planBenefits, setPlanBenefits] = useState({});

  const [currDiet, setCurrDiet] = useState(structuredClone(initialDiet));
  const [optDiet, setOptDiet] = useState(structuredClone(initialDiet));
  const [newDiet, setNewDiet] = useState(structuredClone(initialDiet));

  const [currExercise, setCurrExercise] = useState(
    structuredClone(initialExercise)
  );
  const [optExercise, setOptExercise] = useState(
    structuredClone(initialExercise)
  );
  const [newExercise, setNewExercise] = useState(
    structuredClone(initialExercise)
  );

  const [currSupplements, setCurrSupplements] = useState(
    structuredClone(initialSupplements)
  );
  const [optSupplements, setOptSupplements] = useState(
    structuredClone(initialSupplements)
  );
  const [newSupplements, setNewSupplements] = useState(
    structuredClone(initialSupplements)
  );

  const [currSleep, setCurrSleep] = useState({ sleep: Number('') });
  const [optSleep, setOptSleep] = useState({ sleep: Number('') });
  const [newSleep, setNewSleep] = useState({ sleep: Number('') });

  const [loading, setLoading] = useState(true);
  const [instructions, setInstructions] = useState(false);
  const [isNewData, setIsNewData] = useState(true);
  const baseUrlAWS = process.env.REACT_APP_baseUrl;
  //const baseUrlAWS = process.env.REACT_APP_localBaseUrlAWS;

  // const prediction_model_url = `${baseUrlAWS}api/calculator/prediction_model/`
  // const optimization_model_url = `${baseUrlAWS}api/calculator/optimization_model/`
  const baseModelUrl = process.env.REACT_APP_baseModelUrl;
  const prediction_model_url = `${baseModelUrl}prediction_model/`;
  const optimization_model_url = `${baseModelUrl}optimization_model/`;

  const [recommendedDietaryAllowance, setRecommendedDietaryAllowance] =
    useState({
      meat_processed: 35,
      meat_unprocessed: 35,
      meat_poultry: 35,
      meat_fish: 35,
      fruits_and_veggies: 10,
      dairy: 10,
      grain_whole: 70,
      grain_refined: 70,
      legumes: 70,
      fat_saturated: 300,
      fat_trans: 300,
      refined_sugar: 500,
      artificial_sweetener: 50,
      alcohol: 15,
      cigarettes: 60,
      cardio_low: 1680,
      cardio_medium: 1680,
      cardio_high: 1680,
      strength_training: 1680,
      balance: 7,
      bone_density: 7,
      sauna_frequency: 7,
      sauna_duration: 120,
    });

  const [toxicLevel, setToxicLevel] = useState({
    vitamin_a: 3000,
    vitamin_b6: 100,
    vitamin_b12: Infinity,
    folic_acid: Infinity,
    vitamin_c: 2000,
    vitamin_d: 100,
    vitamin_e: Infinity,
    vitamin_k: Infinity,
    calcium: 3000,
    dietary_fiber: Infinity,
    fish_oil_omega_3: 5,
    green_tea: Infinity,
    iron: 45,
    magnesium: 350,
    olive_oil: Infinity,
    protein_supplements: Infinity,
    zinc: 40,
    water: 8000,
  });

  const { user } = useAuthContext();

  const userId = useMemo(() => {
    let user = localStorage.getItem('mysUser');
    user = user
      ? JSON.parse(user)
      : { userId: '64ad93a2-a276-43e3-8304-be5fbd587ca1' };
    return user.userId;
  }, []);

  const [waterUnit, setWaterUnit] = useState('mL');
  const [greenTeaUnit, setGreenTeaUnit] = useState('mL');
  const [oliveOilUnit, setOliveOilUnit] = useState('g');
  const [weightUnit, setWeightUnit] = useState('g');
  const [waterLimit, setWaterLimit] = useState(7500);
  const [teaLimit, setTeaLimit] = useState(3785);
  const [oilLimit, setOilLimit] = useState(224);
  const [metric, setMetric] = useState(null);
  const [userAge, setUserAge] = useState(null);

  useEffect(() => {
    const fetchUData = async (userId) => {
      console.log('fetchUData');
      try {
        const data = await fetchUserData(userId);
        if (data.preferred_unit_system === 'metric') {
          console.log('unit system: metric');
          setMetric(true);
          setWaterUnit('mL');
          setGreenTeaUnit('mL');
          setOliveOilUnit('g');
          setWaterLimit(7500);
          setToxicLevel({...toxicLevel, water: 8000});
          setTeaLimit(3785);
          setOilLimit(224);
        } else {
          console.log('unit system: imperial');
          setMetric(false);
          setWeightUnit('oz');
          setWaterUnit('oz');
          setToxicLevel({...toxicLevel, water: 270});
          setGreenTeaUnit('oz');
          setOliveOilUnit('Tbsp');
          setWaterLimit(256);
          setTeaLimit(128);
          setOilLimit(16);
        }
      } catch (error) {
        setInstructions(true);
        console.error('Error fetching user data:', error);
      }
    };
    fetchUData(userId);
  }, []);

  useEffect(() => {
    const userInfo = userId;
    const getData = async () => {
      try {
        const response = await axios.get(
          `${baseUrlAWS}api/plan/get/${userInfo}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        handleUpdate(response.data[0]);
      } catch (error) {
        setInstructions(true);
        console.log(error);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const fetchUserInfo = async (userId) => {
      try {
        const res = await getLifestyleResponseData(userId);
        setUserAge(res.data['age']);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchUserInfo(userId);
  }, []);

  const handleUpdate = (data) => {
    setCurrDiet({
      meat_processed: data.meat_processed,
      meat_unprocessed: data.meat_unprocessed,
      meat_poultry: data.meat_poultry,
      meat_fish: data.meat_fish,
      fruits_and_veggies: data.fruits_and_veggies,
      dairy: data.dairy,
      grain_whole: data.grain_whole,
      grain_refined: data.grain_refined,
      legumes: data.legumes,
      fat_saturated: data.fat_saturated,
      fat_trans: data.fat_trans,
      water: data.water,
      refined_sugar: data.refined_sugar,
      artificial_sweetener: data.artificial_sweetener,
      alcohol: data.alcohol,
      cigarettes: data.cigarettes,
      calorie_restriction: data.calorie_restriction,
      fasting: data.fasting,
    });
    setCurrExercise({
      cardio_low: data.cardio_low,
      cardio_medium: data.cardio_medium,
      cardio_high: data.cardio_high,
      strength_training: data.strength_training,
      balance: data.balance,
      bone_density: data.bone_density,
      stretching: data.stretching,
      sauna_frequency: data.sauna_frequency,
      sauna_duration: data.sauna_duration,
    });
    setCurrSupplements({
      multi_vitamins: data.multi_vitamins,
      vitamin_a: data.vitamin_a,
      vitamin_b6: data.vitamin_b6,
      vitamin_b12: data.vitamin_b12,
      folic_acid: data.folic_acid,
      vitamin_c: data.vitamin_c,
      vitamin_d: data.vitamin_d,
      vitamin_e: data.vitamin_e,
      vitamin_k: data.vitamin_k,
      calcium: data.calcium,
      collagen_peptide: data.collagen_peptide,
      dietary_fiber: data.dietary_fiber,
      fish_oil_omega_3: data.fish_oil_omega_3,
      green_tea: data.green_tea,
      iron: data.iron,
      magnesium: data.magnesium,
      olive_oil: data.olive_oil,
      probiotics: data.probiotics,
      protein_supplements: data.protein_supplements,
      zinc: data.zinc,
    });
    setCurrSleep({ sleep: data.sleep });
  };

  //pretend this is the response you get
  //on the frontend, make sure the margin is 0 when the screen is closed, so that there isn't empty spcae
  const [tempBackend, setTempBackend] = useState({
    curr_lifespan: '',
    curr_cancer: '',
    curr_type_2_diabetes: '',
    curr_high_blood_pressure: '',
    curr_cardiovascular_disease: '',
    curr_stroke: '',
    curr_arthiritis: '',
    curr_depression: '',

    opt_lifespan: '',
    opt_cancer: '',
    opt_type_2_diabetes: '',
    opt_high_blood_pressure: '',
    opt_cardiovascular_disease: '',
    opt_stroke: '',
    opt_arthiritis: '',
    opt_depression: '',

    new_lifespan: '',
    new_cancer: '',
    new_type_2_diabetes: '',
    new_high_blood_pressure: '',
    new_cardiovascular_disease: '',
    new_stroke: '',
    new_arthiritis: '',
    new_depression: '',
  });

  async function wait(time) {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  }

  //only need to fetch and set some things once
  // optimization_model
  const [called, setCalled] = useState(false);
  useEffect(() => {
    const fetchModel = async () => {
      console.log('fetchModel');
      setLoading(true);
      var optPredictions = {};
      let userId = JSON.parse(localStorage.getItem('mysUser')).userId;
      userId = userId.replace(/-/g, '');
      // alert(userId)
      if (!called) {
        try {
          await axios
            .post(
              optimization_model_url,
              { user_id: userId },
              // testing using demo userId
              // {"user_id": 'a428442860b170d4d86f15922890a599'},
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            )
            .then((response) => {
              console.log(response.data);
              setCalled(true);

              var optGreenTea = response.data['green_tea']; //ml/day
              // console.log("optimization result")
              // console.log(response.data)

              //convert MET back to mins/week (low only)
              const low = (response.data['cardio'] * 60) / 4;
              const med = (response.data['cardio'] * 60) / 6;
              const high = (response.data['cardio'] * 60) / 8;

              setOptDiet({
                meat_processed: response.data['meat_processed'],
                meat_unprocessed: response.data['meat_unprocessed'],
                meat_poultry: response.data['meat_poultry'],
                meat_fish: response.data['meat_fish'],
                fruits_and_veggies: response.data['fruits_and_veggies'],
                dairy: response.data['dairy'],
                grain_whole: response.data['grain_unrefined'],
                grain_refined: response.data['grain_refined'],
                legumes: response.data['legumes'],
                fat_saturated: response.data['fat_saturated'],
                fat_trans: response.data['fat_trans'],
                water: response.data['water'],
                refined_sugar: response.data['refined_sugar'],
                artificial_sweetener: response.data['artificial_sweetener'],
                alcohol: response.data['alcohol'],
                cigarettes: response.data['smoking_frequency'],
                calorie_restriction: response.data['calorie_restriction'],
              });
              // 'fasting': response.data['intermittent fasting']}) not displayed
              setOptExercise({
                cardio_low: low,
                cardio_medium: med,
                cardio_high: high,
                strength_training: response.data['strength_training'],
                // =================================================================================== //
                balance: 7,
                bone_density: 7,
                stretching: response.data['stretching (day/week)'], // not showup in data
                sauna_frequency: response.data['sauna_frequency'],
                sauna_duration: response.data['sauna_duration'],
              });
              setOptSupplements({
                multi_vitamins: response.data['multi_vitamins'],
                vitamin_a: response.data['vitamin_a'],
                vitamin_b6: response.data['vitamin_b6'],
                vitamin_b12: response.data['vitamin_b12'],
                folic_acid: response.data['folic_acid'],
                vitamin_c: response.data['vitamin_c'],
                vitamin_d: response.data['vitamin_d'],
                vitamin_e: response.data['vitamin_e'],
                vitamin_k: response.data['vitamin_k'],
                calcium: response.data['calcium'],
                collagen_peptide: response.data['collagen_peptide'],
                dietary_fiber: response.data['dietary_fiber'],
                fish_oil_omega_3: response.data['fish_oil_omega_3'],
                green_tea: optGreenTea,
                iron: response.data['iron'],
                magnesium: response.data['magnesium'],
                olive_oil: response.data['olive_oil'],
                probiotics: response.data['probiotics'],
                protein_supplements: response.data['protein_supplements'],
                zinc: response.data['zinc'],
              });
              setOptSleep({ sleep: response.data['sleep_duration'] });

              // origin from jack
              // optPredictions = {
              //   opt_lifespan: Number(response.data['risk_ratios']["predictions_lifespan"]).toFixed(2),
              //   opt_cancer: Number(response.data['risk_ratios']["predictions_cancer (rr)"]).toFixed(2),
              //   opt_type_2_diabetes: Number(response.data['risk_ratios']["predictions_diabetes (rr)"]).toFixed(2),
              //   opt_high_blood_pressure: "NA",
              //   opt_cardiovascular_disease: Number(response.data['risk_ratios']["predictions_cvd/heart disease (rr)"]).toFixed(2),
              //   opt_stroke: Number(response.data['risk_ratios']["predictions_stroke (rr)"]).toFixed(2),
              //   opt_arthiritis: "NA",
              //   opt_depression: Number(response.data['risk_ratios']["predictions_depression (rr)"]).toFixed(2),
              // }
              optPredictions = {
                opt_lifespan: Number(
                  response.data['all_cause_mortality_predicted_lifespan']
                ).toFixed(2),
                opt_cancer: Number(
                  response.data['cancer_predicted_rr']
                ).toFixed(2),
                opt_type_2_diabetes: Number(
                  response.data['diabetes_predicted_rr']
                ).toFixed(2),
                opt_high_blood_pressure: 'NA',
                opt_cardiovascular_disease: Number(
                  response.data['cardio_vascular_disease_predicted_rr']
                ).toFixed(2),
                opt_stroke: Number(
                  response.data['stroke_predicted_rr']
                ).toFixed(2),
                opt_arthiritis: 'NA',
                opt_depression: Number(
                  response.data['depression_predicted_rr']
                ).toFixed(2),
              };
            });
        } catch (error) {
          setInstructions(true);
          console.log(error);
        }
      }

      //give time to set optimal predictions and wait for data to write to db
      await wait(2000);
      try {
        let userId = JSON.parse(localStorage.getItem('mysUser')).userId;
        userId = userId.replace(/-/g, '');
        // alert(userId)
        await axios
          .post(
            prediction_model_url,
            { user_id: userId },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then((response) => {
            if (!called) {
              setTempBackend({
                ...tempBackend,
                opt_lifespan: optPredictions['opt_lifespan'],
                opt_cancer: optPredictions['opt_cancer'],
                opt_type_2_diabetes: optPredictions['opt_type_2_diabetes'],
                opt_high_blood_pressure:
                  optPredictions['opt_high_blood_pressure'],
                opt_cardiovascular_disease:
                  optPredictions['opt_cardiovascular_disease'],
                opt_stroke: optPredictions['opt_stroke'],
                opt_arthiritis: optPredictions['opt_arthiritis'],
                opt_depression: optPredictions['opt_depression'],
                // curr_lifespan: Number(response.data["predictions_lifespan"].toFixed(2)),
                // curr_cancer: Number(response.data["predictions_cancer (rr)"].toFixed(2)),
                // curr_type_2_diabetes: Number(response.data["predictions_diabetes (rr)"].toFixed(2)),
                // curr_high_blood_pressure: "NA",
                // curr_cardiovascular_disease: Number(response.data["predictions_cvd/heart disease (rr)"].toFixed(2)),
                // curr_stroke: Number(response.data["predictions_stroke (rr)"].toFixed(2)),
                // curr_arthiritis: "NA",
                // curr_depression: Number(response.data["predictions_depression (rr)"].toFixed(2)),
                // new_lifespan: Number(response.data["predictions_lifespan"]).toFixed(2),
                // new_cancer: Number(response.data["predictions_cancer (rr)"].toFixed(2)),
                // new_type_2_diabetes: Number(response.data["predictions_diabetes (rr)"].toFixed(2)),
                // new_high_blood_pressure: "NA",
                // new_cardiovascular_disease: Number(response.data["predictions_cvd/heart disease (rr)"].toFixed(2)),
                // new_stroke: Number(response.data["predictions_stroke (rr)"].toFixed(2)),
                // new_arthiritis: "NA",
                // new_depression: Number(response.data["predictions_depression (rr)"].toFixed(2))
                curr_lifespan: Number(
                  response.data[
                    'all_cause_mortality_predicted_lifespan'
                  ].toFixed(2)
                ),
                curr_cancer: Number(
                  response.data['cancer_predicted_rr'].toFixed(2)
                ),
                curr_type_2_diabetes: Number(
                  response.data['diabetes_predicted_rr'].toFixed(2)
                ),
                curr_high_blood_pressure: 'NA',
                curr_cardiovascular_disease: Number(
                  response.data['cardio_vascular_disease_predicted_rr'].toFixed(
                    2
                  )
                ),
                curr_stroke: Number(
                  response.data['stroke_predicted_rr'].toFixed(2)
                ),
                curr_arthiritis: 'NA',
                curr_depression: Number(
                  response.data['depression_predicted_rr'].toFixed(2)
                ),
                new_lifespan: Number(
                  response.data['all_cause_mortality_predicted_lifespan']
                ).toFixed(2),
                new_cancer: Number(
                  response.data['cancer_predicted_rr'].toFixed(2)
                ),
                new_type_2_diabetes: Number(
                  response.data['diabetes_predicted_rr'].toFixed(2)
                ),
                new_high_blood_pressure: 'NA',
                new_cardiovascular_disease: Number(
                  response.data['cardio_vascular_disease_predicted_rr'].toFixed(
                    2
                  )
                ),
                new_stroke: Number(
                  response.data['stroke_predicted_rr'].toFixed(2)
                ),
                new_arthiritis: 'NA',
                new_depression: Number(
                  response.data['depression_predicted_rr'].toFixed(2)
                ),
              });
            } else {
              setTempBackend({
                ...tempBackend,
                new_lifespan: Number(
                  response.data['all_cause_mortality_predicted_lifespan']
                ).toFixed(2),
                new_cancer: Number(
                  response.data['cancer_predicted_rr'].toFixed(2)
                ),
                new_type_2_diabetes: Number(
                  response.data['diabetes_predicted_rr'].toFixed(2)
                ),
                new_high_blood_pressure: 'NA',
                new_cardiovascular_disease: Number(
                  response.data['cardio_vascular_disease_predicted_rr'].toFixed(
                    2
                  )
                ),
                new_stroke: Number(
                  response.data['stroke_predicted_rr'].toFixed(2)
                ),
                new_arthiritis: 'NA',
                new_depression: Number(
                  response.data['depression_predicted_rr'].toFixed(2)
                ),
              });
            }
            setCalled(true);
            setLoading(false);
            setInstructions(false);
            setIsNewData(false);
          });
      } catch (error) {
        setInstructions(true);
        console.log(error);
      }
    };
    if (isNewData) {
      fetchModel();
    }
  }, [isNewData]);
  // }, [newDiet, newExercise, newSupplements, newSleep])

  useEffect(() => {
    if (metric === false) {
      console.log('imperial conversion');
      optDiet['water'] /= 29.5735;
      optSupplements['olive_oil'] /= 13.3;
      optSupplements['green_tea'] /= 29.5735;
      optSupplements['dietary_fiber'] /= 28.35;
    }
  }, [metric, optDiet]);

  const [changedFields, setChangedFields] = useState([]);

  //This function compares the tempBackend with the current benefits table. So it will show which fields have changed upon every render.
  useEffect(() => {
    const changedValues = Object.keys(planBenefits).filter(
      (key) => planBenefits[key] !== tempBackend[key]
    );
    setChangedFields(changedValues);
    setPlanBenefits(tempBackend); //causes two re-renders
    handleLogic();
  }, [planBenefits, tempBackend]);

  const [margin, setMargin] = useState(0);

  //handleLogic represents which sections that we will display when the plan is collapsed.
  //we do this by examining which fields have changed and updating it to true/false
  //we also keep track of the margin, because the more fields we have to end up displaying, the
  //more space we need.
  //TLDR; handleLogic updates benefits form on collapsed item and updates margins as well.
  const handleLogic = () => {
    let total = 0;
    if (window.innerWidth < 992) {
      if (changedFields.length !== 0 && open === false) {
        setHeader(true);
        total += 30;
      } else {
        setHeader(false);
      }
      if (
        changedFields.some((e) =>
          ['curr_lifespan', 'opt_lifespan', 'new_lifespan'].includes(e)
        ) &&
        open === false
      ) {
        setLifespan(true);
        total += 30;
      } else {
        setLifespan(false);
      }
      if (
        changedFields.some((e) =>
          ['curr_cancer', 'opt_cancer', 'new_cancer'].includes(e)
        ) &&
        open === false
      ) {
        setCancer(true);
        total += 30;
      } else {
        setCancer(false);
      }
      if (
        changedFields.some((e) =>
          [
            'curr_type_2_diabetes',
            'opt_type_2_diabetes',
            'new_type_2_diabetes',
          ].includes(e)
        ) &&
        open === false
      ) {
        setType2Diabetes(true);
        total += 30;
      } else {
        setType2Diabetes(false);
      }
      if (
        changedFields.some((e) =>
          [
            'curr_high_blood_pressure',
            'opt_high_blood_pressure',
            'new_high_blood_pressure',
          ].includes(e)
        ) &&
        open === false
      ) {
        setHighBloodPressure(true);
        total += 30;
      } else {
        setHighBloodPressure(false);
      }
      if (
        changedFields.some((e) =>
          [
            'curr_cardiovascular_disease',
            'opt_cardiovascular_disease',
            'new_cardiovascular_disease',
          ].includes(e)
        ) &&
        open === false
      ) {
        setCardiovascularDisease(true);
        total += 30;
      } else {
        setCardiovascularDisease(false);
      }
      if (
        changedFields.some((e) =>
          ['curr_stroke', 'opt_stroke', 'new_stroke'].includes(e)
        ) &&
        open === false
      ) {
        setStroke(true);
        total += 30;
      } else {
        setStroke(false);
      }
      if (
        changedFields.some((e) =>
          ['curr_arthiritis', 'opt_arthiritis', 'new_arthiritis'].includes(e)
        ) &&
        open === false
      ) {
        setArthiritis(true);
        total += 30;
      } else {
        setArthiritis(false);
      }
      if (
        changedFields.some((e) =>
          ['curr_depression', 'opt_depression', 'new_depression'].includes(e)
        ) &&
        open === false
      ) {
        setDepression(true);
        total += 30;
      } else {
        setDepression(false);
      }
    }
    setMargin(total);
  };

  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
    handleLogic();
  };

  // if screen size is over a set amount, set open to false
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 992) {
        // Adjust the pixel width as per your requirement
        setOpen(false);
        setMargin(0);
      }
    };

    handleResize(); // Call the function initially

    window.addEventListener('resize', handleResize); // Add event listener for resize

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //the following variables are boolean variables that determine whether or not the
  //item should be displayed on the collapsable menu. Used in the handleLogic function
  const [header, setHeader] = useState(false);
  const [lifespan, setLifespan] = useState(false);
  const [cancer, setCancer] = useState(false);
  const [type2diabetes, setType2Diabetes] = useState(false);
  const [highbloodpressure, setHighBloodPressure] = useState(false);
  const [cardiovasculardisease, setCardiovascularDisease] = useState(false);
  const [stroke, setStroke] = useState(false);
  const [arthiritis, setArthiritis] = useState(false);
  const [depression, setDepression] = useState(false);
  const [showModal, setShowModal] = useState({
    show: false,
    name: '',
    value: '',
  });

  const handleConfimation = () => {
    sendData({ [showModal.name]: showModal.value }, userId);
    setIsNewData(true);
    setShowModal({show: false, name: '', value: ''});
  };

  //compare frontend value to backend. Get all the ones that have changed and put in a list
  //then run through a series of conditional statements, if "blank" is in the list, then change the useState to true/false

  return (
    <div>
      {showModal && (
        <PopUpWarning
          showModal={showModal}
          handleConfimation={handleConfimation}
        />
      )}
      {/* {instructions ? <PlanInstructions/> : loading ? <LoadingIcon/> : <></>} */}
      {instructions && <PlanInstructions/>}
      {!instructions && loading && <LoadingIcon/>}
      <div style={{ backgroundColor: '#F5F5F5' }}>
        <div className='questionnaire_title'>Make Your Plan</div>

        <div className='container container-details'>
          {/*<button onClick={() => setTempBackend({...tempBackend, curr_lifespan: 10, curr_arthiritis: 12, curr_cancer: 100, curr_high_blood_pressure: 100, curr_depression: 1000})}>Option1</button>
          <button onClick={() => setTempBackend({...tempBackend, curr_lifespan: 100, curr_cancer:10})}>Option2</button>*/}
          <Form onSubmit>
            <Row className='text-center d-flex justify-content-center'>
              <Col lg={6}>
                <div className='sticky-collapse plan-collapse'>
                  <div className='d-flex justify-content-start bg-white'>
                    <Button
                      onClick={handleToggle}
                      variant='secondary'
                      className='my-2 view_benefit_button'
                    >
                      + View Benefits
                    </Button>
                  </div>

                  <div className='table-background bg-white'>
                    <div className='popups collapse-table-background mx-3'>
                      {header ? (
                        <div className='row border border-secondary'>
                          <div className='col-5 border-right border-secondary benefit-label-font'>
                            <strong>Benefits</strong>
                          </div>
                          <div className='col border-right border-secondary'>
                            <strong className='curr_label'></strong>
                          </div>
                          <div className='col border-right border-secondary'>
                            <strong className='opt_label'></strong>
                          </div>
                          <div className='col'>
                            <strong>New</strong>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {lifespan ? (
                        <div className='row border border-secondary'>
                          <div className='col-5 border-right border-secondary'>
                            Lifespan
                          </div>
                          <div className='col border-right border-secondary'>
                            {planBenefits.curr_lifespan}
                          </div>
                          <div className='col border-right border-secondary'>
                            {planBenefits.opt_lifespan}
                          </div>
                          <div className='col'>{planBenefits.new_lifespan}</div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {cancer ? (
                        <div className='row border border-secondary'>
                          <div className='col-5 border-right border-secondary'>
                            Cancer
                          </div>
                          <div className='col border-right border-secondary'>
                            {planBenefits.curr_cancer}
                          </div>
                          <div className='col border-right border-secondary'>
                            {planBenefits.opt_cancer}
                          </div>
                          <div className='col'>{planBenefits.new_cancer}</div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {type2diabetes ? (
                        <div className='row border border-secondary'>
                          <div className='col-5 border-right border-secondary'>
                            Type 2 Diabetes
                          </div>
                          <div className='col border-right border-secondary'>
                            {planBenefits.curr_type_2_diabetes}
                          </div>
                          <div className='col border-right border-secondary'>
                            {planBenefits.opt_type_2_diabetes}
                          </div>
                          <div className='col'>
                            {planBenefits.new_type_2_diabetes}
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {highbloodpressure ? (
                        <div className='row border border-secondary'>
                          <div className='col-5 border-right border-secondary'>
                            High Blood Pressure
                          </div>
                          <div className='col border-right border-secondary'>
                            {planBenefits.curr_high_blood_pressure}
                          </div>
                          <div className='col border-right border-secondary'>
                            {planBenefits.opt_high_blood_pressure}
                          </div>
                          <div className='col'>
                            {planBenefits.new_high_blood_pressure}
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {cardiovasculardisease ? (
                        <div className='row border border-secondary'>
                          <div className='col-5 border-right border-secondary'>
                            Cardiovascular Disease
                          </div>
                          <div className='col border-right border-secondary'>
                            {planBenefits.curr_cardiovascular_disease}
                          </div>
                          <div className='col border-right border-secondary'>
                            {planBenefits.opt_cardiovascular_disease}
                          </div>
                          <div className='col'>
                            {planBenefits.new_cardiovascular_disease}
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {stroke ? (
                        <div className='row border border-secondary'>
                          <div className='col-5 border-right border-secondary'>
                            Stroke
                          </div>
                          <div className='col border-right border-secondary'>
                            {planBenefits.curr_stroke}
                          </div>
                          <div className='col border-right border-secondary'>
                            {planBenefits.opt_stroke}
                          </div>
                          <div className='col'>{planBenefits.new_stroke}</div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {arthiritis ? (
                        <div className='row border border-secondary'>
                          <div className='col-5 border-right border-secondary'>
                            Arthiritis
                          </div>
                          <div className='col border-right border-secondary'>
                            {planBenefits.curr_arthiritis}
                          </div>
                          <div className='col border-right border-secondary'>
                            {planBenefits.opt_arthiritis}
                          </div>
                          <div className='col'>
                            {planBenefits.new_arthiritis}
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {depression ? (
                        <div className='row border border-secondary'>
                          <div className='col-5 border-right border-secondary'>
                            Depression
                          </div>
                          <div className='col border-right border-secondary'>
                            {planBenefits.curr_depression}
                          </div>
                          <div className='col border-right border-secondary'>
                            {planBenefits.opt_depression}
                          </div>
                          <div className='col'>
                            {planBenefits.new_depression}
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>

                  <Collapse
                    in={open}
                    className='collapse-table-background table-background'
                  >
                    <Form.Group>
                      <div className='mx-3'>
                        <Benefits
                          passBenefitsData={(getplan) =>
                            setPlanBenefits(getplan)
                          }
                          getData={planBenefits}
                        />
                      </div>
                    </Form.Group>
                  </Collapse>
                </div>

                <div style={{ marginTop: `${margin}px` }}>
                  <Form.Group className={open ? 'margin-on-collapse' : ''}>
                    <div>
                      <br />
                      Interventions With Asterisk* are still being analyzed by
                      our team
                    </div>

                    <Diet
                      currDiet={currDiet}
                      setCurrDiet={setCurrDiet}
                      optDiet={optDiet}
                      setOptDiet={setOptDiet}
                      newDiet={newDiet}
                      setNewDiet={setNewDiet}
                      waterUnit={waterUnit}
                      waterLimit={waterLimit}
                      weightUnit={weightUnit}
                      loading={loading}
                      setLoading={setLoading}
                      limit={recommendedDietaryAllowance}
                      setIsNewData={setIsNewData}
                      isNewData={isNewData}
                      toxicLevel={toxicLevel}
                      showModal={showModal}
                      setShowModal={setShowModal}
                    />
                    <Execise
                      currExercise={currExercise}
                      setCurrExercise={setCurrExercise}
                      optExercise={optExercise}
                      setOptExercise={setOptExercise}
                      newExercise={newExercise}
                      setNewExercise={setNewExercise}
                      loading={loading}
                      limit={recommendedDietaryAllowance}
                      setIsNewData={setIsNewData}
                      isNewData={isNewData}
                    />
                    <Supplements
                      currSupplements={currSupplements}
                      setCurrSupplements={setCurrSupplements}
                      optSupplements={optSupplements}
                      setOptSupplements={setOptSupplements}
                      newSupplements={newSupplements}
                      setNewSupplements={setNewSupplements}
                      greenTeaUnit={greenTeaUnit}
                      oliveOilUnit={oliveOilUnit}
                      teaLimit={teaLimit}
                      oilLimit={oilLimit}
                      weightUnit={weightUnit}
                      loading={loading}
                      limit={recommendedDietaryAllowance}
                      setLimit={setRecommendedDietaryAllowance}
                      setIsNewData={setIsNewData}
                      isNewData={isNewData}
                      toxicLevel={toxicLevel}
                      setToxicLevel={setToxicLevel}
                      showModal={showModal}
                      setShowModal={setShowModal}
                    />
                    <Sleep
                      currSleep={currSleep}
                      setCurrSleep={setCurrSleep}
                      optSleep={optSleep}
                      setOptSleep={setOptSleep}
                      newSleep={newSleep}
                      setNewSleep={setNewSleep}
                      setIsNewData={setIsNewData}
                      isNewData={isNewData}
                    />
                  </Form.Group>
                </div>
              </Col>

              <Col lg={5} className='d-none d-lg-block m-3'>
                <div className='sticky-property'>
                  <Form.Group>
                    <div className='table-background'>
                      <Benefits
                        passBenefitsData={(getplan) => setPlanBenefits(getplan)}
                        getData={planBenefits}
                        isNewData={isNewData}
                      />
                    </div>
                  </Form.Group>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>

      {userAge !== null && userAge < 18 && (
        <p
          style={{
            textAlign: 'center',
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
        >
          Note: For individuals below the age of 18 years, we show them the
          optimal plan based on government guidelines but don't say what effect
          on lifespan or disease risks these have. We also let them enter a new
          plan but don't estimate effects on lifespan or disease.
        </p>
      )}
    </div>
  );
};

export default Plan;
