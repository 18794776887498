import * as React from "react";
import axios from "axios";

const ExpiredInviteTable = ({ expiredInvites, orgtype, fetchOrganizationData }) => {
  const userid = JSON.parse(localStorage.getItem("mysUser")).userId;

  const baseURL = process.env.REACT_APP_baseUrl;
  const cancelInviteURL = "api/organizations/cancel-invite";
  const resendInviteURL = "api/organizations/invite";

  const handleCancelInvite = (email) => {
    axios
      .post(baseURL + cancelInviteURL, {
        user_id: userid,
        email: email,
        orgtype: orgtype,
      })
      .then((_) => {
        console.log("Invite removed: ", email);
        fetchOrganizationData();
      })
      .catch((error) => {
        console.error("Error removing invite: ", email, error);
      });
  };

  const handleResendInvite = (email) => {
    axios
      .post(baseURL + resendInviteURL, {
        user_id: userid,
        email: email,
        orgtype: orgtype,
      })
      .then((_) => {
        console.log("Resend invite: ", email);
        fetchOrganizationData();
      })
      .catch((error) => {
        console.error("Error sending invite: ", email, error);
      });
  };

  return expiredInvites.length === 0 ? null : (
    <div className="org-item">
      <h4>Expired Invites: </h4>
      <table className="member-table">
        <thead>
          <tr>
            <th>Email</th>
            <th>Expiration Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {expiredInvites.map((invitation) => (
            <tr key={invitation.email}>
              <td>{invitation.email}</td>
              <td>{invitation.invite_expiration_date}</td>
              <td>
                <div>
                  <button
                    className="cancel-button"
                    onClick={() => handleCancelInvite(invitation.email)}
                  >
                    Cancel
                  </button>
                  <button
                    className="resend-button"
                    onClick={() => handleResendInvite(invitation.email)}
                  >
                    Resend
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExpiredInviteTable;
