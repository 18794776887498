import * as React from "react";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import "./Organizations.css";
import "react-toastify/dist/ReactToastify.css";
import CustomEmailValidation from "../Login/CustomEmailValidation";

const InviteForm = ({ orgData, orgtype, fetchOrganizationData }) => {
  const [emails, setEmails] = React.useState([]);
  const [emailInput, setEmailInput] = React.useState('');
  const userid = JSON.parse(localStorage.getItem("mysUser")).userId;
  const baseURL = process.env.REACT_APP_baseUrl;

  const handleInputChange = (e) => {
    setEmailInput(e.target.value);
  };

  const handleAddEmail = () => {
    const emailInputElem = document.querySelector('input[type="email"]');
    if (emailInputElem.checkValidity()) {
      if (emailInput && !emails.includes(emailInput)) {
        setEmails([...emails, emailInput]);
        setEmailInput('');
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddEmail();
    }
  };

  const sendEmails = () => {
    const successEmails = [];
    const failedEmails = [];

    const emailPromises = emails.map((email) => {
      return axios
        .post(baseURL + "api/organizations/invite", {
          user_id: userid,
          email: email,
          orgtype: orgtype,
        })
        .then((_) => {
          successEmails.push(email);
          console.log("Invite sent: ", email);
        })
        .catch((error) => {
          failedEmails.push(email);
          console.error("Error inviting member: ", email, error);
        });
    });

    Promise.all(emailPromises)
      .then(() => {
        if (successEmails.length > 0) {
          toast.success(`Successfully sent ${successEmails.length} invitation${successEmails.length > 1 ? "s" : ""}!`, {
            position: "bottom-right"
          });
        }
        if (failedEmails.length > 0) {
          toast.error(`Failed to send ${failedEmails.length} invitation${failedEmails.length > 1 ? "s" : ""}`, {
            position: "bottom-right"
          });
        }
        setEmails(failedEmails);

        // update pending invites
        fetchOrganizationData();
      })
      .catch((error) => {
        console.error("Error with one or more invites: ", error);
      });
  };

  return (
    orgData.is_manager && (
      <div className="org-item">
        <ToastContainer autoClose={3000} />
        <h4>Invite Members: </h4>
        <div className="email-input-container">
          <CustomEmailValidation
            value={emailInput}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Enter email address"
          />
          <button className="add-email-button" onClick={handleAddEmail}>
            Add Email
          </button>
        </div>
        <div className="email-list">
          {emails.map((email, index) => (
            <div key={index} className="email-list-item">
              {email}
              <button
                className="remove-email-button"
                onClick={() => setEmails(emails.filter((e, i) => i !== index))}
              >
                ×
              </button>
            </div>
          ))}
        </div>
        <button className="invite-button" onClick={sendEmails}>
          Send Invite{emails.length > 1 ? `s` : ""}
        </button>
        <p>Pricing: ${orgData.content.addl_member_cost} per additional member</p>
      </div>
    )
  );
};

export default InviteForm;
