import React from 'react'
import InputMulti from '../../../components/questionnaireElement/InputMulti'
import { useEffect, useState } from 'react'
import { sweetTimeOptions, eatingScheduleOptions, calorieIntakeOptions } from '../Sections/OptionsTable/MyDietOptions'
import {
    sugarySodaLabel, sugaryCakesLabel, sugaryCookiesLabel, sugaryCandyLabel, sugaryIceCreamLabel,
    sugaryAddedSugarLabel, sugaryOtherLabel, artificiallySodaLabel, artificiallyCakesLabel,
    artificiallyCookiesLabel, artificiallyCandyLabel, artificiallyIceCreamLabel,
    artificiallyAddedSugarLabel, artificiallyOtherLabel, waterIntakeLabel, saturatedFatIntakeLabel,
    transFatIntakeLabel, intermittentFastingLabel, calorieRestrictionLabel
} from '../Sections/Lables/MyDiteLabels'
import {
    sugarySodaName, sugaryCakesName, sugaryCookiesName, sugaryCandyName, sugaryIceCreamName,
    sugaryAddedSugarName, sugaryOtherName, artificiallySodaName, artificiallyCakesName,
    artificiallyCookiesName, artificiallyCandyName, artificiallyIceCreamName,
    artificiallyAddedSugarName, artificiallyOtherName, waterIntakeName, saturatedFatIntakeName,
    transFatIntakeName, intermittentFastingName, calorieRestrictionName
} from '../Sections/NameTable/MyDietName'
import { UnitConvert } from '../actions'
const formDataKey = 'IntakeForm'; 
const formDataUnitKey = 'IntakeFormUnit'; 
const formDataUnitDefaultKey = 'IntakeFormUnitDefault';
export default function MyDiet2({waterUnit, dataState, setDataState, unitState, setUnitState, errors, limit, setLimit}) {
  const [cigaretteStatus, setCigaretteStatus] = useState('');
  const [formData, setformData] = useState(JSON.parse(sessionStorage.getItem(formDataKey)) || {});
  const [formDataUnit, setformDataUnit] = useState(JSON.parse(sessionStorage.getItem(formDataUnitKey)) || {});
  const [formDataUnitDefault, setformDataUnitDefault] = useState(JSON.parse(sessionStorage.getItem(formDataUnitDefaultKey)) || {});
  useEffect(() => {
    const savedData = sessionStorage.getItem(formDataKey);
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setformData(parsedData);
    }
  }, []);
  useEffect(() => {
    sessionStorage.setItem(formDataKey, JSON.stringify(formData));
  }, [formData])
  useEffect(() => {
    sessionStorage.setItem(formDataUnitDefaultKey, JSON.stringify(formDataUnitDefault));
  }, [formDataUnitDefault]);  
  const handleInputChange = (name, value) => {
    let newValue = {...dataState, [name]:parseFloat(value)}
    setDataState(newValue)
  };
  const handleUnitChange = (name, value) => {
    const prevUnit = unitState[name]
    const newLimit = UnitConvert(limit[name], prevUnit, value, name).toFixed(2)
    setLimit({...limit, [name]: newLimit})
    const newUnits = {...unitState, [name]: value }
    setUnitState(newUnits)
    const newVal = UnitConvert(dataState[name], prevUnit, value, name)
    setDataState({...dataState, [name]: newVal})
  }
  const handleUnitDefaultChange = (name, value) => {
    setformDataUnitDefault(prevDefaults => ({
        ...prevDefaults,
        [name]: value  
    }));
  }

  return (
          <form>
            <div class='inlineContainer'>
              <small class="form-text">My Diet</small>
            </div>
            <InputMulti inputs={[
              {type: 'InputSingle', label: waterIntakeLabel, options: [waterUnit], name: waterIntakeName},
              {type: 'InputSingle', label: saturatedFatIntakeLabel, options: ['g'], name: saturatedFatIntakeName},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors} limit={limit} setLimit={setLimit}
            />
            <InputMulti inputs={[
              {type: 'InputSingle', label: transFatIntakeLabel, options: ['g'], name: transFatIntakeName},
              {type: 'Menu', label: intermittentFastingLabel, options: eatingScheduleOptions, name: intermittentFastingName},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors} limit={limit} setLimit={setLimit}
            />
            <InputMulti inputs={[
              {type: 'Menu', label: calorieRestrictionLabel, options: calorieIntakeOptions, name: calorieRestrictionName},
              {type: 'null',},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors} limit={limit} setLimit={setLimit}
            />
            <div class='inlineContainer'>
              <div>For each sugary food listed, input how many servings you have for your selected time period</div>
            </div>
            <InputMulti inputs={[
              {type: 'InputSingle', label: sugarySodaLabel, options: sweetTimeOptions, name: sugarySodaName},
              {type: 'InputSingle', label: sugaryCakesLabel, options: sweetTimeOptions, name: sugaryCakesName},
            ]}
            onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
                formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors}
                limit={limit} setLimit={setLimit}
                />
            <InputMulti inputs={[
              {type: 'InputSingle', label: sugaryCookiesLabel, options: sweetTimeOptions, name: sugaryCookiesName},
              {type: 'InputSingle', label: sugaryCandyLabel, options: sweetTimeOptions, name: sugaryCandyName},
            ]}
            onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
                formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors}
                limit={limit} setLimit={setLimit}
                />
            <InputMulti inputs={[
              {type: 'InputSingle', label: sugaryIceCreamLabel, options: sweetTimeOptions, name: sugaryIceCreamName},
              {type: 'InputSingle', label: sugaryAddedSugarLabel, options: sweetTimeOptions, name: sugaryAddedSugarName},
            ]}
            onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
                formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors}
                limit={limit} setLimit={setLimit}
                />
            <InputMulti inputs={[
              {type: 'InputSingle', label: sugaryOtherLabel, options: sweetTimeOptions, name: sugaryOtherName},
              {type: 'null'},
            ]}
            onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
                formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors}
                limit={limit} setLimit={setLimit}
                />
            <div class='inlineContainer'>
              <div>For each artificially sweetened food listed, input how many servings you have for your selected time period</div>
            </div>
            <InputMulti inputs={[
              {type: 'InputSingle', label:  artificiallySodaLabel, options: sweetTimeOptions, name:  artificiallySodaName},
              {type: 'InputSingle', label:  artificiallyCakesLabel, options: sweetTimeOptions, name:  artificiallyCakesName},
            ]}
            onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
                formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors}
                limit={limit} setLimit={setLimit}
                />
            <InputMulti inputs={[
              {type: 'InputSingle', label:  artificiallyCookiesLabel, options: sweetTimeOptions, name:  artificiallyCookiesName},
              {type: 'InputSingle', label:  artificiallyCandyLabel, options: sweetTimeOptions, name:  artificiallyCandyName},
            ]}
            onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
                formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors}
                limit={limit} setLimit={setLimit}
                />
            <InputMulti inputs={[
              {type: 'InputSingle', label:  artificiallyIceCreamLabel, options: sweetTimeOptions, name:  artificiallyIceCreamName},
              {type: 'InputSingle', label:  artificiallyAddedSugarLabel, options: sweetTimeOptions, name:  artificiallyAddedSugarName},
            ]}
            onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
                formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors}
                limit={limit} setLimit={setLimit}
                />
            <InputMulti inputs={[
              {type: 'InputSingle', label:  artificiallyOtherLabel, options: sweetTimeOptions, name:  artificiallyOtherName},
              {type: 'null'},
            ]}
            onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
                formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors}
                limit={limit} setLimit={setLimit}
                />
          </form>
  )
}
