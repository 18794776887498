const fiberDefault = "g/day";
const proteinDefault = "g/day";
const magnesiumDefault = "mg/day";
const manganeseDefault = "mg/day";
const phosphorusDefault = "mg/day";
const potassiumDefault = "mg/day";
const vitaminADefault = "ug/dL";
const vitaminCDefault = "mg/day";
const vitaminDDefault = "ug/week";
const vitaminKDefault = "ug/day";
const biotinDefault = "mcg/day";
const chlorideDefault = "mg/day";
const chromiumDefault = "mcg/day";
const copperDefault = "mg/day";
const folateFolicAcidDefault = "ug/day";
const molybdenumDefault = "mcg/day";
const niacinDefault = "mg/day";
const pantothenicAcidDefault = "mg/day";
const riboflavinDefault = "mg/day";
const seleniumDefault = "mcg/day";
const thiamineDefault = "mg/day";
const cholineDefault = "mg/day";
const vitaminB6Default = "mg/day";
const vitaminB12Default = "ug/day";
const vitaminEDefault = "IU/day";
const zincDefault = "mg/day";
const iodineDefault = "mcg/day";
const ironDefault = "mg/day";
const nickelDefault = "mcg/day";
const fishOilOmega3Default = "g/day";
const melatoninDefault = "mg/day";
const turmericDefault = "mg/day";
const garlicDefault = "mg/day";
const matchaGreenTeaDefault = "NA/day"; // Assume "NA/day" means not applicable, thus no default
const plantSterolsDefault = "mg/day";
const nmnDefault = "mg/day";
const nrDefault = "mg/day";
const calciumAKGDefault = "mg/day";
const alphaLipoicAcidDefault = "mg/day";
const quercetinDefault = "mg/day";
const pqqDefault = "mg/day";
const hyaluronicAcidDefault = "mg/day";
const chlorellaDefault = "mg/day";
const spirulinaDefault = "mg/day";
const reveratrolDefault = "mg/day";
const coQ10Default = "mg/day";
const acetylLCarnitineDefault = "mg/day";
const oliveOilDefault = "g/day"; // Assume "NA/day" means not applicable, thus no default
const luteinDefault = "mg/day";
const milkThistleDefault = "mg/day";
const spermidineDefault = "mg/day";
const creatineDefault = "g/day";
const trimethylGlycineDefault = "mg/day";
const calciumDefault = "mg/day";

export {
    fiberDefault, proteinDefault, magnesiumDefault, manganeseDefault, phosphorusDefault, potassiumDefault,
    vitaminADefault, vitaminCDefault, vitaminDDefault, vitaminKDefault, biotinDefault, chlorideDefault,
    chromiumDefault, copperDefault, folateFolicAcidDefault, molybdenumDefault, niacinDefault, pantothenicAcidDefault,
    riboflavinDefault, seleniumDefault, thiamineDefault, cholineDefault, vitaminB6Default, vitaminB12Default,
    vitaminEDefault, zincDefault, iodineDefault, ironDefault, nickelDefault, fishOilOmega3Default,
    melatoninDefault, turmericDefault, garlicDefault, matchaGreenTeaDefault, plantSterolsDefault, nmnDefault,
    nrDefault, calciumAKGDefault, alphaLipoicAcidDefault, quercetinDefault, pqqDefault, hyaluronicAcidDefault,
    chlorellaDefault, spirulinaDefault, reveratrolDefault, coQ10Default, acetylLCarnitineDefault, oliveOilDefault,
    luteinDefault, milkThistleDefault, spermidineDefault, creatineDefault, trimethylGlycineDefault, calciumDefault
};
