import React from 'react';

const Instructions = () => (
  <div className='loading-overlay'>
      <p className='spinner-text'>Please complete the Lifespan Calculator and the My Profile intake form before utilizing this page.</p>
  </div>
  
);

export default Instructions;
