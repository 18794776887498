import { useState } from "react"
import { confirmPassword, updateUserResetStatus } from "./auth"

export default function ResetPassword() {
    console.log("reset password");

    
  const [email, setEmail] = useState("")
  const [confirmationCode, setConfirmationCode] = useState("")
  const [password, setPassword] = useState("")
  const [password2, setPassword2] = useState("")
  const [displayPassword, setDisplayPassword] = useState(false)
  const [passwordType, setPasswordType] = useState("password")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)


  const handleSubmit = async (e) => {
    e.preventDefault()
    setError("")
    
    setIsLoading(true);
    if (!email || !password || !password2 || !confirmationCode) {
      setError("Please complete all fields.");
        return;
    }
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])[A-Za-z\d\^\$\*\.\[\]\{\}\(\)\?"!@#%&\/\\,><':;|_~`+=\s]{8,}$/;
    if (!passwordRegex.test(password)) {
      setError("Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.");
        return;
    }
    if (password !== password2) {
      setError("Passwords do not match.");
        return;
    }

    
    try {
      await confirmPassword(email, confirmationCode, password)
      setSuccess(true)
    } catch (err) {
        console.log("ERROR FROM RESET PASSWORD");
      setError(err.message)
    }
  }

  if (success) {
    // Added for reset setEmail information
    updateUserResetStatus(email, -1)
    return (
      <div>
        <br />
        <br />
        <br />
        <br />
        <h2>Reset password</h2>
        <p>Your password has been reset successfully!</p>
      </div>
    )
  }
  const handlePasswordDisplay = () => {
    if (displayPassword === true) {
      setDisplayPassword(false)
      setPasswordType("password")
    } else if (displayPassword === false) {
      setDisplayPassword(true)
      setPasswordType("text")
    }
  }

  return (
    <div>
        <br />
        <br />
        <br />
        <br />
        
      <div className="cognito_login_box">
        <form onSubmit={handleSubmit}>
        <h4 className="cognito_login_title">Reset Password</h4>
        <p>Please check your email for the verification code to reset your password. </p>
        <label className="cognito_login_label">Email:</label>
          <input
          className="cognito_lable_box"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
           <label className="cognito_login_label">Confirmation Code:</label>

          <input

            type="text"
          className="cognito_lable_box"
            value={confirmationCode}
            onChange={(e) => setConfirmationCode(e.target.value)}
          />
              <label className="cognito_login_label">Password:</label>
              <div>
                <input className="cognito_lable_box" type={passwordType} onChange={(e) => {
                  setPassword(e.target.value)
                }}
                  value={password}
                />
              </div>
              <label className="cognito_login_label">Confirm password:</label>
              <div>
                <input className="cognito_lable_box" type={passwordType} onChange={(e) => {
                  setPassword2(e.target.value)
                }}
                  value={password2}
                />
              </div>
              <div>
                <input style={{ marginRight: "10px", marginTop: "10px" }} type="checkbox" onChange={() => {
                  handlePasswordDisplay()
                }}
                />
                {displayPassword && <span>Show password</span>}
                {!displayPassword && <span>Hide password</span>}
              </div>
          <button className="cognito_login_submit">Submit</button>
          <p className = "cognito_login_error">{error}</p>
        </form>
        </div>
        
    </div>
  )
}