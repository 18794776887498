import { useState, useMemo, useEffect } from "react";
import { Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom"
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from 'axios'
import {getCookie} from "../../../utils/cookie"
import { fetchScores } from "../action";

function PittSleepQuality() {

  const [submittedToday, setSubmittedToday] = useState(true)

  useEffect(() => {
    const fetchTestScores = async (userId) => {
        try {
            const data = await fetchScores(userId)
            
            if (data.filter(data => data.testId === 'pitt').length > 0) {
                const testDate = new Date(data.filter(data => data.testId === 'pitt')[0].submission_date.substring(0,10).split('-')).toUTCString().substring(0,16)
                const currDate = new Date().toUTCString().substring(0,16)
                
                if (testDate === currDate) {
                  setSubmittedToday(true)
                } else {
                  setSubmittedToday(false)
                }
            } else {
              setSubmittedToday(false)
            }
            
        } catch (error) {
            console.error('Error fetching test scores', error)
        }
    }
    fetchTestScores(userId)
  }, [])

  const baseUrl = process.env.REACT_APP_baseUrl;
  const baseUrlAWS =`${baseUrl}api/myprogress`
  let navigate = useNavigate();

  const[submitted, setSubmitted] = useState(false)
  const[score, setScore] = useState()

  // sleep measures
  const [timeToBed, setTimeToBed] = useState("22:00");
  const [durationToBed, setDurationToBed] = useState(0);
  const [timeWakeUp, setTimeWakeUp] = useState("08:00");
  const [totalSleep, setTotalSleep] = useState(0);

  // responses for sleep measures question 5
  const [cannotSleept30, setCannotSleept30] = useState("notPastMonth");
  const [wakeNightOrMorning, setWakeNightOrMorning] = useState("notPastMonth");
  const [useBathroom, setUseBathroom] = useState("notPastMonth");
  const [cannotBreatheComfortably, setCannotBreatheComfortably] =
    useState("notPastMonth");
  const [coughSnoreLoudly, setCoughSnoreLoudly] = useState("notPastMonth");
  const [tooCold, setTooCold] = useState("notPastMonth");
  const [tooHot, setTooHot] = useState("notPastMonth");
  const [badDreams, setBadDreams] = useState("notPastMonth");
  const [havePain, setHavePain] = useState("notPastMonth");
  const [sleepOther, setSleepOther] = useState("notPastMonth");

  //6,7,8,9
  const [sleepQual, setSleepQual] = useState("veryGood")
  const [medicine, setMedicine] = useState("notPastMonth")
  const [stayAwake, setStayAwake] = useState("notPastMonth")
  const [enthusiasm, setEnthusiasm] = useState("noProblem")

  // responses for question 10
  const [partner, setPartner] = useState("NA")
  const [snoring, setSnoring] = useState("NA")
  const [pauses, setPauses] = useState("NA")
  const [twitching, setTwitching] = useState("NA")
  const [confusion, setConfusion] = useState("NA")
  const [restlessness, setRestlessness] = useState("NA")

  //for calculations
  const dict = {
    notPastMonth: 0,
    lessThanOnceWeek: 1,
    onceOrTwiceWeek: 2,
    ThreeOrMoreWeek: 3,
    veryGood: 0,
    fairlyGood: 1,
    fairlyBad: 2,
    veryBad: 3,
    noProblem: 0,
    slightProblem: 1,
    somewhatProblem: 2,
    bigProblem: 3
  }


  const userId = useMemo(() => {
    let user = localStorage.getItem("mysUser");
      user = user
        ? JSON.parse(user)
        : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
    return user.userId;
  })

  const calculateScore = () => {
    
    //component 1
    const comp1 = dict[sleepQual]

    //component 2
    var sleepLatency = 0
    if (durationToBed > 60) {
      sleepLatency = 3
    } else if (durationToBed > 30) {
      sleepLatency = 2
    } else if (durationToBed > 15) {
      sleepLatency = 1
    }

    var sleepLatency2 = dict[cannotSleept30]
    const totalLatency = sleepLatency + sleepLatency2

    var sl = 0
    if (totalLatency > 4) {
      sl = 3
    } else if (totalLatency > 2) {
      sl = 2
    } else if (totalLatency > 0) {
      sl = 1
    }

    const comp2 = sl

    //component 3
    var sd = 0
    if (totalSleep < 5) {
      sd = 3
    } else if (totalSleep < 6) {
      sd = 2
    } else if (totalSleep < 7) {
      sd = 1
    } 

    const comp3 = sd
    

    //component 4
    var timeBed = timeToBed.split(':')
    var timeUp = timeWakeUp.split(':')
    var minsInBed = 0

    if ((+timeBed[0]) > 11) {
      let up = (+timeUp[0]) * 60 + (+timeUp[1]) + 1440
      let bed = (+timeBed[0]) * 60 + (+timeBed[1])
      minsInBed = up - bed
    } else {
      let up = (+timeUp[0]) * 60 + (+timeUp[1])
      let bed = (+timeBed[0]) * 60 + (+timeBed[1])
      minsInBed = up - bed
    }

    var minsSlept = totalSleep * 60
    const sleepEfficiency = (minsSlept/minsInBed) * 100
    var comp4 = 0
    if (sleepEfficiency < 65) {
      comp4 = 3
    } else if (sleepEfficiency < 75) {
      comp4 = 2
    } else if (sleepEfficiency < 85) {
      comp4 = 1
    }

    //component 5
    const c5 = dict[wakeNightOrMorning] + dict[useBathroom] + dict[cannotBreatheComfortably] + dict[coughSnoreLoudly] + dict[tooCold] + dict[tooHot] + dict[badDreams] + dict[havePain] + dict[sleepOther]
    
    var disturbances = 0
    if (c5 > 18) {
      disturbances = 3
    } else if (c5 > 9) {
      disturbances = 2
    } else if (c5 > 0) {
      disturbances = 1
    }

    const comp5 = disturbances

    //component 6
    const comp6 = dict[medicine]

    //component 7
    const q8 = dict[stayAwake]
    const q9 = dict[enthusiasm]

    var dysfunction = 0
    if (q8 + q9 > 4) {
      dysfunction = 3
    } else if (q8 + q9 > 2){
      dysfunction = 2 
    } else if (q8 + q9 > 0){
      dysfunction = 1
    }

    const comp7 = dysfunction

    return comp1 + comp2 + comp3 + comp4 + comp5 + comp6 + comp7 
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const s = calculateScore()
    setScore(s)
    setSubmitted(true)

    let testRes = {}
    testRes["user"] = userId
    testRes["testId"] = "pitt"
    testRes["score"] = s

    submitData(testRes)
    console.log(testRes)

  };

  // call API to submit the form data 
  const submitData = (testData) => {
  const csrfToken = getCookie("csrftoken")
  console.log(JSON.stringify(testData))
  axios.post(
      `${baseUrlAWS}/create-progress-test-score-entry/`,
      testData,
      {
          headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
          },
      })
      .then(response => {
          console.log(response)
      })
      .catch(error => {
          if (error.response && error.response.status === 500) {
              return null
          } else {
              console.error('Error fetching form data:', error)
              throw error
          }
      })
  }

  return (
    <div>

      <Container>
        <div>
          <Form onSubmit={handleSubmit}>
            <h3
              style={{
                textAlign: "center",
                marginTop: "65px",
                marginBottom: "30px",
              }}
            >
              Pittsburgh Sleep Quality Index
            </h3>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "5px",
              }}
            >
              <div style={{ maxWidth: "80%" }}>
                <Form.Text>
                  Instructions: The following questions relate to your usual
                  sleep habits during the past month only. Your answers should
                  indicate the most accurate reply for the majority of days and
                  nights in the past month.
                </Form.Text>
              </div>
            </div>
            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                minHeight: "50px"
              }}
            >
              <Form.Label
                style={{ display: "flex", flexWrap: "wrap", maxWidth: "60%" }}
              >
                1. During the past month, what time have you usually gone to bed
                at night?
              </Form.Label>
              <Form.Control
                type="time"
                placeholder=""
                id="timeToBed"
                name="timeToBed"
                value={timeToBed}
                style={{ width: "250px", maxHeight: "38px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setTimeToBed(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                minHeight: "50px"
              }}
            >
              <Form.Label
                style={{ display: "flex", flexWrap: "wrap", maxWidth: "60%" }}
              >
                2. During the past month, how long (in minutes) has it usually
                taken you to fall asleep each night?
              </Form.Label>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip-2">Please enter a valid nonnegative number</Tooltip>}
                show={!/^(?!0\d)\d*(?:\.\d+)?$/.test(durationToBed) && durationToBed !== ''}
              >
              <Form.Control
                type="number"
                placeholder=""
                id="durationToBed"
                name="durationToBed"
                value={durationToBed}
                style={{ width: "250px", maxHeight: "38px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setDurationToBed(e.target.value);
                }}
              ></Form.Control>
              </OverlayTrigger>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                minHeight: "50px"
              }}
            >
              <Form.Label
                style={{ display: "flex", flexWrap: "wrap", maxWidth: "60%" }}
              >
                3. During the past month, what time have you usually gotten up
                in the morning?
              </Form.Label>
              <Form.Control
                type="time"
                placeholder=""
                id="timeWakeUp"
                name="timeWakeUp"
                value={timeWakeUp}
                style={{ width: "250px", maxHeight: "38px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setTimeWakeUp(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                minHeight: "50px"
              }}
            >
              <Form.Label
                style={{ display: "flex", flexWrap: "wrap", maxWidth: "60%" }}
              >
                4. During the past month, how many hours of actual sleep did you
                get at night? (This may be different from the number of hours
                spent in bed).
              </Form.Label>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip-2">Please enter a valid nonnegative number</Tooltip>}
                show={!/^(?!0\d)\d*(?:\.\d+)?$/.test(totalSleep) && totalSleep !== ''}
              >
              <Form.Control
                type="number"
                placeholder=""
                id="totalSleep"
                name="totalSleep"
                value={totalSleep}
                style={{ width: "250px", maxHeight: "38px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setTotalSleep(e.target.value);
                }}
              ></Form.Control>
              </OverlayTrigger>
            </Form.Group>
            <Form.Label>
              5. During the past month, how often have you had trouble sleeping
              because you...
            </Form.Label>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label
                htmlFor="cannotSleept30"
                style={{ marginLeft: "20px" }}
              >
                a<span>&#41;</span> Cannot get to sleep within 30 minutes
              </Form.Label>
              <Form.Control
                as="select"
                name="cannotSleept30"
                id="cannotSleept30"
                value={cannotSleept30}
                style={{ width: "250px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setCannotSleept30(e.target.value);
                }}
              >
                <option value="notPastMonth">Not during the past month</option>
                <option value="lessThanOnceWeek">Less than once a week</option>
                <option value="onceOrTwiceWeek">Once or twice a week</option>
                <option value="ThreeOrMoreWeek">
                  Three or more times a week
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label
                htmlFor="wakeNightOrMorning"
                style={{ marginLeft: "20px" }}
              >
                b<span>&#41;</span> Wake up in the middle of the night or early
                morning
              </Form.Label>
              <Form.Control
                as="select"
                name="wakeNightOrMorning"
                id="wakeNightOrMorning"
                value={wakeNightOrMorning}
                style={{ width: "250px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setWakeNightOrMorning(e.target.value);
                }}
              >
                <option value="notPastMonth">Not during the past month</option>
                <option value="lessThanOnceWeek">Less than once a week</option>
                <option value="onceOrTwiceWeek">Once or twice a week</option>
                <option value="ThreeOrMoreWeek">
                  Three or more times a week
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label htmlFor="useBathroom" style={{ marginLeft: "20px" }}>
                c<span>&#41;</span> Have to get up to use the bathroom
              </Form.Label>
              <Form.Control
                as="select"
                name="useBathroom"
                id="useBathroom"
                value={useBathroom}
                style={{ width: "250px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setUseBathroom(e.target.value);
                }}
              >
                <option value="notPastMonth">Not during the past month</option>
                <option value="lessThanOnceWeek">Less than once a week</option>
                <option value="onceOrTwiceWeek">Once or twice a week</option>
                <option value="ThreeOrMoreWeek">
                  Three or more times a week
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label
                htmlFor="cannotBreatheComfortably"
                style={{ marginLeft: "20px" }}
              >
                d<span>&#41;</span> Cannot breathe comfortably
              </Form.Label>
              <Form.Control
                as="select"
                name="cannotBreatheComfortably"
                id="cannotBreatheComfortably"
                value={cannotBreatheComfortably}
                style={{ width: "250px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setCannotBreatheComfortably(e.target.value);
                }}
              >
                <option value="notPastMonth">Not during the past month</option>
                <option value="lessThanOnceWeek">Less than once a week</option>
                <option value="onceOrTwiceWeek">Once or twice a week</option>
                <option value="ThreeOrMoreWeek">
                  Three or more times a week
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label
                htmlFor="coughSnoreLoudly"
                style={{ marginLeft: "20px" }}
              >
                e<span>&#41;</span> Cough or snore loudly
              </Form.Label>
              <Form.Control
                as="select"
                name="coughSnoreLoudly"
                id="coughSnoreLoudly"
                value={coughSnoreLoudly}
                style={{ width: "250px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setCoughSnoreLoudly(e.target.value);
                }}
              >
                <option value="notPastMonth">Not during the past month</option>
                <option value="lessThanOnceWeek">Less than once a week</option>
                <option value="onceOrTwiceWeek">Once or twice a week</option>
                <option value="ThreeOrMoreWeek">
                  Three or more times a week
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label htmlFor="tooCold" style={{ marginLeft: "20px" }}>
                f<span>&#41;</span> Feel too cold
              </Form.Label>
              <Form.Control
                as="select"
                name="tooCold"
                id="tooCold"
                value={tooCold}
                style={{ width: "250px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setTooCold(e.target.value);
                }}
              >
                <option value="notPastMonth">Not during the past month</option>
                <option value="lessThanOnceWeek">Less than once a week</option>
                <option value="onceOrTwiceWeek">Once or twice a week</option>
                <option value="ThreeOrMoreWeek">
                  Three or more times a week
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label htmlFor="tooHot" style={{ marginLeft: "20px" }}>
                g<span>&#41;</span> Feel too hot
              </Form.Label>
              <Form.Control
                as="select"
                name="tooHot"
                id="tooHot"
                value={tooHot}
                style={{ width: "250px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setTooHot(e.target.value);
                }}
              >
                <option value="notPastMonth">Not during the past month</option>
                <option value="lessThanOnceWeek">Less than once a week</option>
                <option value="onceOrTwiceWeek">Once or twice a week</option>
                <option value="ThreeOrMoreWeek">
                  Three or more times a week
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label htmlFor="badDreams" style={{ marginLeft: "20px" }}>
                h<span>&#41;</span> Had bad dreams
              </Form.Label>
              <Form.Control
                as="select"
                name="badDreams"
                id="badDreams"
                value={badDreams}
                style={{ width: "250px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setBadDreams(e.target.value);
                }}
              >
                <option value="notPastMonth">Not during the past month</option>
                <option value="lessThanOnceWeek">Less than once a week</option>
                <option value="onceOrTwiceWeek">Once or twice a week</option>
                <option value="ThreeOrMoreWeek">
                  Three or more times a week
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label htmlFor="havePain" style={{ marginLeft: "20px" }}>
                i<span>&#41;</span> Have a pain
              </Form.Label>
              <Form.Control
                as="select"
                name="havePain"
                id="havePain"
                value={havePain}
                style={{ width: "250px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setHavePain(e.target.value);
                }}
              >
                <option value="notPastMonth">Not during the past month</option>
                <option value="lessThanOnceWeek">Less than once a week</option>
                <option value="onceOrTwiceWeek">Once or twice a week</option>
                <option value="ThreeOrMoreWeek">
                  Three or more times a week
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label htmlFor="havePain" style={{ marginLeft: "20px" }}>
                j<span>&#41;</span> Other reason(s)
              </Form.Label>
              <Form.Control
                as="select"
                name="sleepOther"
                id="sleepOther"
                value={sleepOther}
                style={{ width: "250px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setSleepOther(e.target.value);
                }}
              >
                <option value="notPastMonth">Not during the past month</option>
                <option value="lessThanOnceWeek">Less than once a week</option>
                <option value="onceOrTwiceWeek">Once or twice a week</option>
                <option value="ThreeOrMoreWeek">
                  Three or more times a week
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                marginTop: "20px",
                minHeight: "50px"
              }}
            >
              <Form.Label
                style={{ display: "flex", flexWrap: "wrap", maxWidth: "60%" }}
              >
                6. During the past month, how would you rate your sleep quality overall?
              </Form.Label>
              <Form.Control
                as="select"
                name="sleepQual"
                id="sleepQual"
                value={sleepQual}
                style={{ width: "250px", maxHeight: "38px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setSleepQual(e.target.value);
                }}
              >
                <option value="veryGood">Very good</option>
                <option value="fairlyGood">Fairly good</option>
                <option value="fairlyBad">Fairly bad</option>
                <option value="veryBad">Very bad</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                minHeight: "50px"
              }}
            >
              <Form.Label
                style={{ display: "flex", flexWrap: "wrap", maxWidth: "60%" }}
              >
                7. During the past month, how often have you taken medicine to help you sleep 
                (prescribed or "over the counter")?
              </Form.Label>
              <Form.Control
                as="select"
                name="medicine"
                id="medicine"
                value={medicine}
                style={{ width: "250px", maxHeight: "38px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setMedicine(e.target.value);
                }}
              >
                <option value="notPastMonth">Not during the past month</option>
                <option value="lessThanOnceWeek">Less than once a week</option>
                <option value="onceOrTwiceWeek">Once or twice a week</option>
                <option value="ThreeOrMoreWeek">
                  Three or more times a week
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                minHeight: "50px"
              }}
            >
              <Form.Label
                style={{ display: "flex", flexWrap: "wrap", maxWidth: "60%" }}
              >
                8. During the past month, how often have you had trouble staying awake while driving,
                eating meals, or engaging in social activity?
              </Form.Label>
              <Form.Control
                as="select"
                name="stayAwake"
                id="stayAwake"
                value={stayAwake}
                style={{ width: "250px", maxHeight: "38px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setStayAwake(e.target.value);
                }}
              >
                <option value="notPastMonth">Not during the past month</option>
                <option value="lessThanOnceWeek">Less than once a week</option>
                <option value="onceOrTwiceWeek">Once or twice a week</option>
                <option value="ThreeOrMoreWeek">
                  Three or more times a week
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                minHeight: "50px"
              }}
            >
              <Form.Label
                style={{ display: "flex", flexWrap: "wrap", maxWidth: "60%" }}
              >
                9. During the past month, how much of a problem has it been for you to keep
                up enough enthusiasm to get things done?
              </Form.Label>
              <Form.Control
                as="select"
                name="enthusiasm"
                id="enthusiasm"
                value={enthusiasm}
                style={{ width: "250px", maxHeight: "38px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setEnthusiasm(e.target.value);
                }}
              >
                <option value="noProblem">No problem at all</option>
                <option value="slightProblem">Only a slight problem</option>
                <option value="somewhatProblem">Somewhat of a problem</option>
                <option value="bigProblem">
                  A very big problem
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                minHeight: "50px"
              }}
            >
              <Form.Label
                style={{ display: "flex", flexWrap: "wrap", maxWidth: "60%" }}
              >
                10. Do you have a bed partner or room mate
              </Form.Label>
              <Form.Control
                as="select"
                name="partner"
                id="partner"
                value={partner}
                style={{ width: "250px", maxHeight: "38px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setPartner(e.target.value);
                }}
              >
                <option value="NA">No bed partner or room mate</option>
                <option value="otherRoom">Partner/room mate in other room</option>
                <option value="sameRoom">Partner in same room, but not same bed</option>
                <option value="sameBed">
                  Partner in same bed
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Label>
              If you have a room mate or bed partner, ask him/her how often in the past
              month you have had...
            </Form.Label>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label
                htmlFor="snoring"
                style={{ marginLeft: "20px" }}
              >
                a<span>&#41;</span> Loud snoring
              </Form.Label>
              <Form.Control
                as="select"
                name="snoring"
                id="snoring"
                value={snoring}
                style={{ width: "250px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setSnoring(e.target.value);
                }}
              >
                <option value="NA">No bed partner or room mate</option>
                <option value="notPastMonth">Not during the past month</option>
                <option value="lessThanOnceWeek">Less than once a week</option>
                <option value="onceOrTwiceWeek">Once or twice a week</option>
                <option value="ThreeOrMoreWeek">
                  Three or more times a week
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label
                htmlFor="pauses"
                style={{ marginLeft: "20px" }}
              >
                b<span>&#41;</span> Long pauses between breaths while asleep
              </Form.Label>
              <Form.Control
                as="select"
                name="pauses"
                id="pauses"
                value={pauses}
                style={{ width: "250px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setPauses(e.target.value);
                }}
              >
                <option value="NA">No bed partner or room mate</option>
                <option value="notPastMonth">Not during the past month</option>
                <option value="lessThanOnceWeek">Less than once a week</option>
                <option value="onceOrTwiceWeek">Once or twice a week</option>
                <option value="ThreeOrMoreWeek">
                  Three or more times a week
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label htmlFor="twitching" style={{ marginLeft: "20px" }}>
                c<span>&#41;</span> Legs twitching or jerking while you sleep
              </Form.Label>
              <Form.Control
                as="select"
                name="twitching"
                id="twitching"
                value={twitching}
                style={{ width: "250px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setTwitching(e.target.value);
                }}
              >
                <option value="NA">No bed partner or room mate</option>
                <option value="notPastMonth">Not during the past month</option>
                <option value="lessThanOnceWeek">Less than once a week</option>
                <option value="onceOrTwiceWeek">Once or twice a week</option>
                <option value="ThreeOrMoreWeek">
                  Three or more times a week
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label htmlFor="confusion" style={{ marginLeft: "20px" }}>
                d<span>&#41;</span> Episodes of disorientation or confusion during sleep
              </Form.Label>
              <Form.Control
                as="select"
                name="confusion"
                id="confusion"
                value={confusion}
                style={{ width: "250px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setConfusion(e.target.value);
                }}
              >
                <option value="NA">No bed partner or room mate</option>
                <option value="notPastMonth">Not during the past month</option>
                <option value="lessThanOnceWeek">Less than once a week</option>
                <option value="onceOrTwiceWeek">Once or twice a week</option>
                <option value="ThreeOrMoreWeek">
                  Three or more times a week
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label htmlFor="restlessness" style={{ marginLeft: "20px" }}>
                e<span>&#41;</span> Other restlessness while you sleep
              </Form.Label>
              <Form.Control
                as="select"
                name="restlessness"
                id="restlessness"
                value={restlessness}
                style={{ width: "250px" }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setRestlessness(e.target.value);
                }}
              >
                <option value="NA">No bed partner or room mate</option>
                <option value="notPastMonth">Not during the past month</option>
                <option value="lessThanOnceWeek">Less than once a week</option>
                <option value="onceOrTwiceWeek">Once or twice a week</option>
                <option value="ThreeOrMoreWeek">
                  Three or more times a week
                </option>
              </Form.Control>
            </Form.Group>

            <div style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
              {(!submittedToday && !submitted) && (
                <Button type="submit" variant="dark" style={{ minWidth: "225px" }}>
                  Submit
                </Button>
              )}
              {( submittedToday || submitted) && (
                <div>
                  <Button type="submit" variant="dark" style={{ minWidth: "225px" }} disabled>
                    Submit
                  </Button>
                  <p style={{color: "gray", fontSize: "14px", textAlign: 'center'}}>(Already submitted today)</p>
                </div>
              )}
            </div>
          </Form>
          <div>
            {submitted && (
              <div>
                <div style={{display: "flex", justifyContent: "center", marginTop: "30px"}}>Score: {score}/21</div>
                <p style={{color: "gray", fontSize: "14px",display: "flex", justifyContent: "center"}}>(Where higher values represent worse sleep quality)</p>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default PittSleepQuality;